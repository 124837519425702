import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const viewUser = async (id: any, identifier: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/user/${id}/${identifier}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listUsers = async (id: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/user/from-company/${id}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listUsersForRoot = async (id: any, rootId: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/user/from-company/for-root/${id}/${rootId}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const createUser = async (companyIdentifier: any, data: any): Promise<any> => {
  try {
    const response = await axios.post<any>(
      `${API_URL}/user/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    console.error("Error creating transaction", error);
    throw error;
  }
};

const updateUser = async (id: any, companyIdentifier: any, data: any): Promise<any> => {
  try {
    const response = await axios.put<any>(
      `${API_URL}/user/${id}/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    console.error("Error creating transaction", error);
    throw error;
  }
};

export { listUsers, listUsersForRoot, createUser, updateUser, viewUser };
