import ContainerSingle from "../../../Component/Campaigns/Single/Single";

const Single = () => {
  return (
    <div className="page-body">
      <ContainerSingle />
    </div>
  );
};

export default Single;
