import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import SweetAlert from "sweetalert2";
import CommonModal from "./CommonModalContact";
import { Formik } from "formik";
import FormValidations from "./ValidationsForm";
import FormValidationsImport from "./ValidationsFormImport";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createContactBlackList,
  createContactBlackListByFile,
  deleteContactBlackList,
  listContactsBlackList,
  updateContactBlackList,
  viewContactBlackList,
} from "../../../Service/contactsBlackList";
import CardHeaderCommon from "../../BonusUi/CardHeaderCommon/CardHeaderCommon";
import { format } from "date-fns";

const ContainerBlacklistContacts = () => {
  const { t } = useTranslation();
  const { id, name } = useParams<{ id: string; name: string }>();
  const navigate = useNavigate();

  const mainTitle: any = name;
  const base = `${process.env.PUBLIC_URL}/contact/contactlist`;

  const [modalTitle, setModalTitle] = useState("");

  const [idEdit, setIdEdit] = useState<any>();
  const [data, setData] = useState<any>();
  const [blackList, setBlackList] = useState(false);
  const [loading, setLoading] = useState(false);

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const fetchData = useCallback(async () => {
    try {
      let response: any = [];
      await listContactsBlackList(identifier, id)
        .then((e: any) => {
          setBlackList(true);
          response = e;
        })
        .catch(async () => {
          await listContactsBlackList(identifier, id)
            .then((e: any) => {
              response = e;
              setBlackList(false);
            })
            .catch((error: any) => {
              console.error("Erro ao buscar dados:", error);
            });
        });
      
      const contacts = response.map((contact: any, index: number) => {
        return {
          ...contact,
          number: contact.number,
          date_in: contact.date_in,
          date_out: contact.date_out,
          created_at: new Date(contact.created_at),
          identifier: contact.identifier,
          index: index,
        };
      });
        
      setData(contacts);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id, identifier]);

  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [importContacts, setImportContacts] = useState(false);

  const [centred, setCentered] = useState(false);
  const [centredImport, setCenteredImport] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const centeredToggleImport = () => setCenteredImport(!centredImport);
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const ExcludeConfirm = () => {
      SweetAlert.fire({
        title: t("alert_title"),
        text: t("alert_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("alert_cancel"),
        confirmButtonText: t("alert_button"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteContactBlackList(row.row.identifier, id, identifier)
            .then(() => {
              fetchData();
              SweetAlert.fire({
                title: t("alert_confirm_title"),
                text: t("alert_confirm_text_contact"),
                icon: "success",
              });
            })
            .catch(() => {
              SweetAlert.fire({
                title: t("alert_confirm_title_error"),
                text: t("alert_confirm_text_contact_error"),
                icon: "error",
              });
            });
        }
      });
    };
    const handleItemClick = async (action: any) => {
      if (action === "delete") {
        ExcludeConfirm();
        setDropdownOpen(false);
      } else if (action === "edit") {
        setIdEdit(row.row);
        setEdit(true);
        add && setAdd(false);
        importContacts && setImportContacts(false);
        await viewContactBlackList(row.row.identifier, id, identifier).then((e: any) => {
          e.date_in = format(e.date_in, "yyyy-MM-dd");
          e.date_out = format(e.date_out, "yyyy-MM-dd");

          setValidationFormInitialValue(e);
        });
        centeredToggle();
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("edit")}>
            {t("edit")}
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick("delete")}>
            {t("delete")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const columns = [
    {
      name: t("number"),
      selector: (row: any) => `${row.number}`,
      sortable: true,
    },
    {
      name: t("date_in"),
      selector: (row: any) => `${format(row.date_in, "yyyy-MM-dd")}`,
      sortable: true,
    },
    {
      name: t("date_out"),
      selector: (row: any) => `${format(row.date_out, "yyyy-MM-dd")}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const formatPhoneNumber = (number: any) => {
        return number.replace(/[^\d+]/g, "");
      };

      const contactBlackListData = {
        number: formatPhoneNumber(values.number),
        date_in: new Date(values.date_in),
        date_out: new Date(values.date_out),
        blacklist_identifier: id,
        company_identifier: identifier,
      };

      const dataToSubmit = contactBlackListData;

      edit
        ? await updateContactBlackList(dataToSubmit, idEdit.identifier, identifier).then(() => {
            fetchData();
            centeredToggle();
            resetForm();
            toast.success(t("success_contact"));
            setSubmitError(false);
          })
        : await createContactBlackList(identifier, dataToSubmit).then(() => {
            fetchData();
            centeredToggle();
            resetForm();
            toast.success(t("success_contact"));
            setSubmitError(false);
          });
    } catch (error) {
      console.error("Error saving contact", error);
      setSubmitError(true);
      toast.error(t("error_contact"));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitImport = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const contactData = {
        file: values.files,
        blacklist_identifier: id,
      };

      const dataToSubmit = contactData;

      console.log('DATA TO SUBMIT FILE CONTACTS BLACKLIST: ', dataToSubmit);

      await createContactBlackListByFile(identifier, dataToSubmit).then(() => {
        fetchData();
        centeredToggle();
        resetForm();
        toast.success(t("success_contact"));
        setSubmitError(false);
      });

      navigate(`${process.env.PUBLIC_URL}/contact/blacklist`);
    } catch (error) {
      console.error("Error saving contact", error);
      setSubmitError(true);
      toast.error(t("error_contact"));
    } finally {
      setLoading(false);
    }
  };

  const [validationFormInitialValueImport] = useState<any>({
    contacts: "",
    duplicity: "",
  });

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      number: "",
      date_in: "",
      date_out: "",
    });

  const formValidationSchema = Yup.object().shape({
    number: Yup.string().required(t("required_field")),
    date_in: Yup.string().required(t("required_field")),
    date_out: Yup.string().required(t("required_field")),
  });

  useEffect(() => {
    if (add) {
      setModalTitle(t("add_contact_title"));
    } else if (edit) {
      setModalTitle(t("edit_contact_title"));
    } else if (importContacts) {
      setModalTitle(t("import_contact_title"));
    }
  }, [add, edit, fetchData, importContacts, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData, t]);

  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t(blackList === true ? "blacklist" : "contactlist")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <div style={{ visibility: "hidden" }}>
                  <CardHeaderCommon title={t("Lista de contatos 01")} />
                </div>
                <div
                  className="btn-search-companies"
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      edit && setEdit(false);
                      add && setAdd(false);
                      setImportContacts(true);
                      centeredToggleImport();
                    }}
                  >
                    {t("import_contact")}
                  </Button>

                  <Button
                    color="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      edit && setEdit(false);
                      importContacts && setImportContacts(true);
                      setAdd(true);
                      centeredToggle();
                    }}
                  >
                    {t("add_contact")}
                  </Button>
                </div>
                <CommonModal
                  size="lg"
                  backdrop="static"
                  centered
                  title={modalTitle}
                  isOpen={add || edit ? centred : centredImport}
                  toggle={add || edit ? centeredToggle : centeredToggleImport}
                >
                  <div className="modal-toggle-wrapper">
                    {add || edit ? (
                      <Formik
                        initialValues={validationFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={formValidationSchema}
                        enableReinitialize={true}
                      >
                        {({ errors }) => (
                          <FormValidations
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            loading={loading}
                          />
                        )}
                      </Formik>
                    ) : (
                      <Formik
                        initialValues={validationFormInitialValueImport}
                        onSubmit={handleSubmitImport}
                      >
                        <FormValidationsImport blackList={blackList} />
                      </Formik>
                    )}
                  </div>
                </CommonModal>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={customStyles}
                    subHeader
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerBlacklistContacts;
