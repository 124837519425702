import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { listCompaniesForRootForSelect, listCompaniesForSelect } from "../../../Service/company";
import { CompanyTypeEnum } from "../../../Enums/company.type.enum";
import { listServicesForSelect, viewServicesForOwner } from "../../../Service/service";
import { set } from "date-fns";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  setSubmitError,
  dataSelect,
  loading,
  currentRowId,
  isEditing,
}) => {
  const { t } = useTranslation();

  const [inLoading, setInLoading] = useState<boolean>(false);

  const userData = localStorage.getItem("M2C:userData");
  
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const companyType = userData
    ? JSON.parse(userData)?.userInfo?.company_type
    : null;

  const [gatewayReadOnly, setGatewayReadOnly] = useState<boolean>(false);
  const [serviceReadOnly, setServiceReadOnly] = useState<boolean>(false);
  const [companyReadOnly, setCompanyReadOnly] = useState<boolean>(false);
  
  const [companies, setCompanies] = useState<any[]>([]);

  const [serviceData, setServiceData] = useState<any[]>([]);

  const isButtonDisabled = loading || inLoading;

  const [serviceRecord, setServiceRecord] = useState<any>();

  const fetchCompanies = async () => {
    try {
      if (companyType === CompanyTypeEnum.OWNER) {
        const response = await listCompaniesForRootForSelect(identifier);
        setCompanies(response);
      } else {
        const response = await listCompaniesForSelect(identifier);
        setCompanies(response);
      }
    } catch (error) {
      console.error("Error fetching companies", error);
    }
  }

  const fetchServices = async () => {
    try {
      if (companyType === CompanyTypeEnum.OWNER) {
        setServiceData([]);

        if (identifier && currentRowId) {
          const service = await viewServicesForOwner(identifier, currentRowId);

          if (!service) {
            setServiceData([]);
          } else {
            setServiceRecord(service);

            const response = await listServicesForSelect(service.created_by);

            if (response) {
              setServiceData(response);
            }
          }
        }
      } else {
        setServiceData([]);

        if (identifier) {
          const response = await listServicesForSelect(identifier);
          
          if (response) {
            setServiceData(response);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching companies", error);
    }
  }

  const getCompanyType = (type: string) => {
    switch (type) {
      case CompanyTypeEnum.OWNER:
        return t("owner");

      case CompanyTypeEnum.RESELLER:
        return t("reseller");

      case CompanyTypeEnum.COMPANY:
        return t("company");

      case CompanyTypeEnum.BRANCH:
      default:
        return t("branch");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setInLoading(true);

      try {
        await Promise.all([fetchCompanies(), fetchServices()]);
      } catch (error) {
        console.error("Error in fetching data", error);
      } finally {
        setInLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (companyType === CompanyTypeEnum.OWNER) {
      if (serviceRecord && serviceRecord.service_identifier && serviceRecord.service_identifier !== "") {
        setGatewayReadOnly(true);
        setServiceReadOnly(false);
      } else {
        setGatewayReadOnly(false);
        setServiceReadOnly(true);
      }
    }

    if (companyType !== CompanyTypeEnum.OWNER) {
      setServiceReadOnly(false);
      setGatewayReadOnly(true);
    }

    if (isEditing) {
      setGatewayReadOnly(true);
      setServiceReadOnly(true);
      setCompanyReadOnly(true);
    }
  }, [dataSelect, serviceRecord]);

  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("name_label")}</Label>
            <Field
              name="name"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("name_placeholder")}
            />
            <ErrorMessage
              name="name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("type")}</Label>
            <Field
              as="select"
              name="type"
              className={`form-control ${
                submitErrors && `${errors.type ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value={""}>{""}</option>
              <option value={"sms"}>{"SMS"}</option>
            </Field>
            <ErrorMessage
              name="type"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("cost")}</Label>
            <InputGroup>
              <InputGroupText>{"R$"}</InputGroupText>
              <Field
                name="cost"
                type="number"
                className={`form-control ${
                  submitErrors &&
                  `${errors.own_cost ? "is-invalid" : "is-valid"}`
                }`}
              />
            </InputGroup>
            <ErrorMessage
              name="cost"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        {companyType === CompanyTypeEnum.OWNER && (
          <Col xs="12" md="12">
            <FormGroup>
              <Label check>{t("Gateway")}</Label>
              <Field
                as="select"
                name="gateway_identifier"
                className={`form-control ${
                  submitErrors &&
                  `${errors.gateway_identifier ? "is-invalid" : "is-valid"}`
                }`}
              >
                <option value={""}>{""}</option>
                {dataSelect &&
                  dataSelect.map((item: any, index: number) => (
                    <option value={item.identifier} key={index}>
                      {item.name}
                    </option>
                  ))}
              </Field>
              <ErrorMessage
                name="gateway_identifier"
                component="span"
                className="invalid-feedback"
              />
            </FormGroup>
          </Col>
          
        )}
        
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("Service")}</Label>
            <Field
              disabled={serviceReadOnly}
              as="select"
              name="service_identifier"
              className={`form-control ${
                submitErrors &&
                `${errors.service_identifier ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value={""}>{""}</option>
              {serviceData &&
                serviceData.map((item: any, index: number) => (
                  <option value={item.identifier} key={index}>
                    {item.name}
                  </option>
                ))}
            </Field>
            <ErrorMessage
              name="service_identifier"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("Company")}</Label>
            <Field
              disabled={companyReadOnly}
              as="select"
              name="company_identifier"
              className={`form-control ${
                submitErrors &&
                `${errors.company_identifier ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value={""}>{""}</option>
              {companies &&
                companies.map((item: any, index: number) => (
                  <option value={item.identifier} key={index}>
                    {getCompanyType(item.type)} - {item.document} - {item.name}
                  </option>
                ))}
            </Field>
            <ErrorMessage
              name="company_identifier"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("active")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="active" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="active"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("concatenated")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="concatenated" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="concatenated"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("prepaid")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="pre_paid" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="pre_paid"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={isButtonDisabled}
          >
            {isButtonDisabled ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
