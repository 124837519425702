import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { Badges } from "../../../AbstractElements";
import { deleteCompany, listCompanies, listCompaniesForRoot } from "../../../Service/company";
import { CompanyTypeEnum } from "../../../Enums/company.type.enum";
import { format } from "date-fns";

const ContainerCompanies = () => {
  const { t } = useTranslation();

  const mainTitle = "Companies";
  const base = `${process.env.PUBLIC_URL}/configurations/companies`;
  const textBase = [{ text: t("company_text") }];

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const companyType = userData ? JSON.parse(userData)?.userInfo?.company_type : null;

  const [data, setData] = useState<any>();

  const fetchData = useCallback(async () => {
    try {
      if (companyType === CompanyTypeEnum.OWNER) {
        const response = await listCompaniesForRoot(identifier);
        setData(response.companies);
        return
      } else {      
        const response = await listCompanies(identifier);
        setData(response.companies);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [identifier]);

  const ExcludeConfirm = (id: string | undefined) => {
    SweetAlert.fire({
      title: t("alert_title"),
      text: t("alert_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("alert_cancel"),
      confirmButtonText: t("alert_button"),
    }).then(async (result) => {
      if (result.isConfirmed) {
      }
      if (result.isConfirmed) {
        await deleteCompany(id)
          .then(() => {
            fetchData();
            SweetAlert.fire({
              title: t("alert_confirm_title"),
              text: t("alert_confirm_text"),
              icon: "success",
            });
          })
          .catch(() => {
            SweetAlert.fire({
              title: t("alert_confirm_title_error"),
              text: t("alert_confirm_text_error"),
              icon: "error",
            });
          });
      }
    });
  };

  const ColumnAction = ({ row }: { row: any }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleItemClick = (action: any, key?: string) => {
      if (action === "delete") {
        ExcludeConfirm(key);
        setDropdownOpen(false);
      } else if (action === "edit") {
        navigate(`${process.env.PUBLIC_URL}/companies/edit/${key}`);
        setDropdownOpen(false);
      } else if (action === "users") {
        navigate(
          `${process.env.PUBLIC_URL}/configurations/companies/users/${row.identifier}`
        );
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {(row.type !== CompanyTypeEnum.OWNER && row.identifier !== identifier) && (
            <>
              <DropdownItem onClick={() => handleItemClick("edit", row.identifier)}>
                {t("edit")}
              </DropdownItem>
              <DropdownItem
                onClick={() => handleItemClick("delete", row.identifier)}
              >
                {t("delete")}
              </DropdownItem>
            </>
          )}
          <DropdownItem onClick={() => handleItemClick("users")}>
            {t("manage_users")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const StatusChip = ({ status }: any) => {
    return status ? (
      <Badges className="badge-light-success" color="transparant">
        {t("active")}
      </Badges>
    ) : (
      <Badges className="badge-light-danger" color="transparant">
        {t("inactive")}
      </Badges>
    );
  };

  const TypeChip = ({ type }: any) => {
    switch (type) {
      case CompanyTypeEnum.OWNER:
        return (
          <Badges className="btn-primary btn" color="transparant">
            {t("owner")}
          </Badges>
        );
      
      case CompanyTypeEnum.RESELLER:
        return (
          <Badges className="btn-success btn" color="transparant">
            {t("reseller")}
          </Badges>
        );
      
      case CompanyTypeEnum.COMPANY:
        return (
          <Badges className="btn-secondary btn" color="transparant">
            {t("company")}
          </Badges>
        );

      case CompanyTypeEnum.BRANCH:
          return (
            <Badges className="btn-warning btn" color="transparant">
              {t("branch")}
            </Badges>
          );

      default:
        return (
          <Badges className="btn-warning btn" color="transparant">
            {t("branch")}
          </Badges>
        );
    }
  };

  const columns = [
    {
      name: t("document"),
      selector: (row: any) => `${row.document}`,
      sortable: true,
    },
    {
      name: t("name"),
      selector: (row: any) => `${row.name}`,
      sortable: true,
    },
    {
      name: t("type"),
      selector: (row: any) => row.type,
      cell: (row: any) => <TypeChip type={row.type} />,
      sortable: true,
    },
    {
      name: t("top_company"),
      selector: (row: any) => `${row?.parent?.name ?? '-'}`,
      sortable: true,
    },
    {
      name: t("Status"),
      selector: (row: any) => row.active,
      cell: (row: any) => <StatusChip status={row.active} />,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };
  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <CardHeaderCommon title={t("Companies")} span={textBase} />
                <div className="btn-search-companies">
                  <Button
                    color="primary"
                    tag={Link}
                    to={`${process.env.PUBLIC_URL}/companies/add`}
                  >
                    {t("add_company")}
                  </Button>
                </div>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerCompanies;
