import { Card, CardBody, Col, Input, Label, Table } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, OpenInvoicesHeading, Weekly, Yearly } from "../../../../utils/Constant";
import OpenInvoicesTableBody from "./OpenInvoicesTableBody";

const OpenInvoices = () => {
  return (
    <Col xxl="7" lg="8" className="box-col-8e">
      <Card>
        <CommonCardHeader headClass="pb-0" title={OpenInvoicesHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody className="open-invoice">
          <div className="custom-scrollbar table-responsive">
            <Table className="display" id="invoice" style={{ width: "100%" }} >
              <thead> 
                <tr>
                  <th>
                    <div className="form-check">
                      <Input type="checkbox" />
                      <Label />
                    </div>
                  </th>
                  <th>Invoice</th>
                  <th>Customer</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Due Date</th>
                  <th> </th>
                </tr>
              </thead>
              <OpenInvoicesTableBody />
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default OpenInvoices;
