import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const listBlackList = async (companyId: string | undefined): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/blacklist/${companyId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching black lists", error);
    throw error;
  }
};

const viewBlackList = async (id_list: any, companyId: any): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/blacklist/${id_list}/${companyId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching campaign", error);
    throw error;
  }
};

const createBlackList = async (companyId:string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/blacklist/${companyId}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating black list", error);
    throw error;
  }
};

const updateBlackList = async (data: any, id: any, companyId: string): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/blacklist/${id}/${companyId}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating black list", error);
    throw error;
  }
};

const deleteBlackList = async (
  identifier: string | undefined,
  companyId: string | undefined
): Promise<boolean> => {
  try {
    const response = await axios.delete(
      `${API_URL}/blacklist/${identifier}/${companyId}`,
      getAuthHeaders()
    );
    return response.status === 204;
  } catch (error) {
    console.error("Error deleting Gateway", error);
    throw error;
  }
};

export {
  viewBlackList,
  listBlackList,
  createBlackList,
  updateBlackList,
  deleteBlackList,
};
