import { Card, CardBody, Col, Input, Label } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, ProjectSummeryHeading, SearchTableButton, Weekly, Yearly } from "../../../../utils/Constant";
import DataTable from "react-data-table-component";
import { projectSummeryTableBodyData, projectSummeryTableHeaderData } from "../../../../Data/Dashboard/Project/Project";
import { useMemo, useState } from "react";

const ProjectSummery = () => {
  const [filterText, setFilterText] = useState("");

  const filteredItems = projectSummeryTableBodyData.filter((item) =>item.projectName && item.projectName.toLowerCase().includes(filterText.toLowerCase()));
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="dataTables_filter d-flex align-items-center">
        <Label className="me-2">{SearchTableButton}:</Label>
        <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
      </div>
    );
  }, [filterText]);
  return (
    <Col xl="7">
      <Card>
        <CommonCardHeader headClass="pb-0" title={ProjectSummeryHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody className="project-summary">
          <DataTable className="custom-scrollbar table-responsive" data={filteredItems} columns={projectSummeryTableHeaderData} pagination subHeaderComponent={subHeaderComponentMemo} selectableRows paginationPerPage={3}/>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProjectSummery;
