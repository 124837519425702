import ContainerSMSReports from "../../../Component/Campaigns/Sms/SmsReports";

const SMSReports = () => {
  return (
    <div className="page-body">
      <ContainerSMSReports />
    </div>
  );
};

export default SMSReports;
