import { Col, Container, Row } from 'reactstrap'
import SingleBlogData from './SingleBlogData/SingleBlogData'
import CommentSection from './CommentSection/CommentSection'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { Blog, BlogSingle } from '../../../../utils/Constant'

const BlogSingleContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={BlogSingle} parent={Blog} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <div className="blog-single">
              <SingleBlogData />
              <CommentSection />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default BlogSingleContainer