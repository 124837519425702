import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const listCredits = async (
  companyIdentifier: any,
  filters: {
    type?: string;
    direction?: string;
    page?: number;
    limit?: number;
  } = {}
): Promise<any[]> => {
  try {
    const { type, direction, page = 1, limit = 10 } = filters;
    const params: any = {
      page,
      limit,
      order: "amount",
    };

    if (type) {
      params.type = type;
    }

    if (direction) {
      params.direction = direction;
    }

    const response = await axios.get<any[]>(
      `${API_URL}/transactions/reseller/list/${companyIdentifier}`,
      {
        params,
        ...getAuthHeaders(),
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const createCredit = async (data: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.post<any>(
      `${API_URL}/transactions/reseller/add-credits/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    console.error("Error creating transaction", error);
    throw error;
  }
};

const deleteCredit = async (id: string, companyIdentifier: string): Promise<void> => {
  try {
    await axios.delete(
      `${API_URL}/transactions/reseller/delete/${id}/${companyIdentifier}`,
      getAuthHeaders()
    );
  } catch (error) {
    console.error("Error deleting transaction", error);
    throw error;
  }
}

const listCompanyToSelect = async (companyIdentifier: string): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(`${API_URL}/company/reseller/get-many/${companyIdentifier}`, getAuthHeaders());

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Companies not found");
    } else {
      throw error;
    }
  }
}

export {
  listCredits,
  createCredit,
  deleteCredit,
  listCompanyToSelect,
};
