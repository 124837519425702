import { Card, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import CommonCardHeaderInitial from '../../../../CommonElements/CommonCardHeaderInitial/CommonCardHeaderInitial';
import CommonCardEarningInitial from '../CommonCardEarningInitial/CommonCardEarningInitial';

const DailyCost: React.FC<any> = ({data}) => {
  const { t } = useTranslation();

  return (
    <Col xl="2" md="5" sm="6" className="col-xl-25 box-col-3">
      <Card className="earning-card">
        <CommonCardHeaderInitial title={t('daily_cost')} mainTitle={true}/>
        <CommonCardEarningInitial amount={`$ ${data}`} />
      </Card>
    </Col>
  );
};

export default DailyCost;
