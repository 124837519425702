import ContainerServices from "../../../Component/Configurations/Services/Services";

const Services = () => {
  return (
    <div className="page-body">
      <ContainerServices />
    </div>
  );
};

export default Services;
