import { Card, CardBody, Col } from "reactstrap";
import { H4, H5, H6, Progressbar, SVG } from "../../../../AbstractElements";

const CommonDesign = ({color,title,completeValue,progressValue,userName ,date,svgIcon}:any) => {
  return (
    <Col md="12" sm="6">
      <Card className="project-task">
        <CardBody>
          <div className="website-design">
            <div className="d-flex">
              <H4 className={`font-${color}`}>{title}</H4>
              <span>Running</span>
            </div>
            <div className="progress-value">
              <H5>Task Completed: {completeValue}/10</H5>
              <Progressbar value={progressValue} color={color} />
            </div>
            <div className="client">
              <div className="flex-grow-1">
                <H6>Client: {userName}</H6>
                <span>Deadline: {date}</span>
              </div>
              <div className="flex-shrink-0">
                <div className={`design-card bg-light-${color}`}>
                  <SVG iconId={svgIcon} />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CommonDesign;
