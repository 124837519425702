import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Dashboard, EcommerceDashboard } from "../../../utils/Constant";
import TotalRevenue from "./TotalRevenue/TotalRevenue";
import DailyVisitors from "./DailyVisitors/DailyVisitors";
import TopProduct from "./TopProduct/TopProduct";
import OpenInvoices from "./OpenInvoices/OpenInvoices";
import TopCategories from "./TopCategories/TopCategories";
import TrackOrder from "./TrackOrder/TrackOrder";
import ProductSales from "./ProductSales/ProductSales";
import ManageOrder from "./ManageOrder/ManageOrder";
import StockReport from "./StockReport/StockReport";

const ContainerEcommerce = () => {
  return (
    <>
      <Breadcrumbs mainTitle={EcommerceDashboard} parent={Dashboard} />
      <Container fluid className="ecommerce-dashboard">
        <Row>
          <TotalRevenue />
          <DailyVisitors />
          <TopProduct />
          <OpenInvoices />
          <TopCategories />
          <TrackOrder />
          <ProductSales />
          <ManageOrder />
          <StockReport />
        </Row>
      </Container>
    </>
  );
};

export default ContainerEcommerce;
