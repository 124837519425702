import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState, useEffect, useCallback } from "react";
import FormValidations from "./ValidationsForm";
import { toast } from "react-toastify";
import {
  createGateway,
  viewGateway,
  updateGateway,
} from "../../../Service/gateway";
import { useNavigate, useParams } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";

const ContainerGatewayAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    active: false,
    host: "",
    port: "",
    user: "",
    password: "",
    own_cost: "",
    speed: "",
    portability_delivery: "",
    concatenated_rule: "",
    concatenated_delivery: "",
    operator: [],
    failover: false,
    charge_mo_processed: false,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required_field")),
    active: Yup.boolean().required(t("required_field")),
    host: Yup.string().required(t("required_field")),
    port: Yup.number().required(t("required_field")),
    user: Yup.string().required(t("required_field")),
    password: Yup.string().required(t("required_field")),
    own_cost: Yup.number().required(t("required_field")),
    speed: Yup.number().required(t("required_field")),
    portability_delivery: Yup.string().required(t("required_field")),
    concatenated_rule: Yup.string().required(t("required_field")),
    concatenated_delivery: Yup.string().required(t("required_field")),
    // operator: Yup.string().required(t("required_field")),
    failover: Yup.boolean().required(t("required_field")),
    charge_mo_processed: Yup.boolean().required(t("required_field")),
  });

  const fetchGateway = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response: any = await viewGateway(id);
        if (response.operator) {
          response.operator = response.operator
            .split(", ")
            .map((op: any) => op.trim());
        }
        setInitialValues(response);
      } catch (error) {
        console.error("Erro ao buscar gateway:", error);
        toast.error(t("error_gateway"));
      } finally {
        setLoading(false);
      }
    },
    [t]
  );
  const cleanGatewayData = (data: any) => {
    const cleanedData = { ...data };
    delete cleanedData.identifier;
    delete cleanedData.created_at;
    delete cleanedData.updated_at;
    delete cleanedData.deleted;
    delete cleanedData.deleted_at;
    return cleanedData;
  };

  useEffect(() => {
    if (id) {
      fetchGateway(id);
    }
  }, [fetchGateway, id]);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      let gatewayData: any = {
        ...values,
        port: parseInt(values.port),
        operator: values.operator ? values.operator.join(", ") : "",
      };

      gatewayData = cleanGatewayData(gatewayData);

      if (id) {
        await updateGateway(id, gatewayData);
        toast.success(t("success_gateway"));
      } else {
        await createGateway(gatewayData);
        resetForm();
        toast.success(t("success_gateway"));
      }

      setSubmitError(false);
      navigate(`${process.env.PUBLIC_URL}/configurations/gateway`);
    } catch (error) {
      console.error("Error saving gateway", error);
      setSubmitError(true);
      toast.error(t("error_gateway"));
    } finally {
      setLoading(false);
    }
  };

  const operators = [
    "Claro",
    "TIM",
    "Vivo",
    "Oi",
    "Algar Telecom",
    "Nextel",
    "Sercomtel",
    "Datora",
    "Porto Seguro Conecta",
    "Correios Celular",
    "Surf Telecom",
    "Veek",
    "Fluke",
    "Vivo Easy",
    "TIM Beta",
    "Claro Flex",
    "Intercel",
    "Meu Chip",
    "Best Buy Sim",
    "EasySIM4U",
  ];

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card style={{ marginTop: "20px" }}>
            <CardBody>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, setFieldValue, setFieldTouched, values }) => (
                  <FormValidations
                    errors={errors}
                    submitErrors={submitError}
                    setSubmitError={setSubmitError}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    loading={loading}
                    operators={operators}
                  />
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContainerGatewayAdd;
