import { Col } from "reactstrap";
import { LI, UL } from "../../../../AbstractElements";
import ReactApexChart from "react-apexcharts";
import { totalRevenueChartData } from "../../../../Data/Dashboard/DashboardChart/DashboardChart";

const TotalRevenueChart = () => {
  return (
    <Col lg="9" md="12" className="col-xl-100">
      <div className="revenue-legend">
        <UL className="simple-list flex-row">
          <LI className="me-3">
            <div className="circle bg-primary me-1"> </div>
            <span>Earning</span>
          </LI>
          <LI>
            <div className="circle bg-secondary me-1" />
            <span>Expense </span>
          </LI>
        </UL>
      </div>
      <div className="revenue-chart" >
        <ReactApexChart options={totalRevenueChartData} series={totalRevenueChartData.series} height={316} type="bar" />
      </div>
    </Col>
  );
};

export default TotalRevenueChart;
