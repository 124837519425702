import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Dashboard, DefaultDashboard } from "../../../utils/Constant";
import YearlySmsMessagesSent from "./YearlySmsMessagesSent/YearlySmsMessagesSent";
import YearlySmsSpend from "./YearlySmsSpend/YearlySmsSpend";
import SmsDaily from "./SmsDaily/SmsDaily";
import SmsDailyDeliveredNotDelivered from "./SmsDailyDeliveredNotDelivered/SmsDailyDeliveredNotDelivered";
import DailyCost from "./DailyCost/DailyCost";
import MonthlyCost from "./MonthlyCost/MonthlyCost";
import { useEffect, useState } from "react";
import { listInitialSmsStatus, listMonthlyInitialSmsStatus, listYearlyInitialSmsStatus } from "../../../Service/reports";
import { useTranslation } from "react-i18next";
import YearlyCost from "./YearlyCost/YearlyCost";
import DailyTotalSms from "./DailyTotalSms/DailyTotalSms";
import DailyDeliveredSms from "./DailyDeliveredSms/DailyDeliveredSms";
import DailyOtherStatesSms from "./DailyOtherStatesSms/DailyOtherStatesSms";

const ContainerDefault = () => {
  const { t } = useTranslation();

  const userData = localStorage.getItem("M2C:userData");
  const companyIdentifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const [graphSmsDaily, setGraphSmsDaily] = useState([] as any);
  const [graphSmsDeliveredNotDelivered, setGraphSmsDeliveredNotDelivered] = useState([] as any);
  const [graphDailyCost, setGraphDailyCost] = useState(0);
  const [graphSmsMonthlyCost, setGraphSmsMonthlyCost] = useState(0);
  const [graphSmsYearly, setGraphSmsYearly] = useState([] as any);
  const [graphSmsYearlyCost, setGraphSmsYearlyCost] = useState(0);
  const [dailyTotalSMS, setDailyTotalSMS] = useState(0);
  const [dailyDeliveredSMS, setDailyDeliveredSMS] = useState(0);
  const [dailyUndeliveredSMS, setDailyUndeliveredSMS] = useState(0);

  const handleSmsDailyGraph = async () => {
    let listParams = {
      company_identifier: companyIdentifier,
      actual_date: new Date(),
    };

    const response = await listInitialSmsStatus(companyIdentifier, listParams);

    const total = response?.total ?? 0;
    const delivery = response?.delivered ?? 0;
    const undelivery = response?.undelivered ?? 0;
    const scheduled = response?.scheduled ?? 0;
    const enroute = response?.enroute ?? 0;
    const rejected = response?.rejected ?? 0;
    const accepted = response?.accepted ?? 0;
    const invalidNumber = response?.invalidNumber ?? 0;
    const processing = response?.processing ?? 0;
    const waiting = response?.waiting ?? 0;
    const unknown = response?.unknown ?? 0;
    const expired = response?.expired ?? 0;
    const notSent = response?.error ?? 0;

    const cost = response?.cost.toFixed(3) ?? 0;

    setGraphDailyCost(cost);

    if (response) {
      setGraphSmsDaily([
        ["Status", "Quantity"],
        [`${t("Delivered")} - ${delivery}`, delivery],
        [`${t("Undelivered")} - ${undelivery}`, undelivery],
        [`${t("Scheduled")} - ${scheduled}`, scheduled],
        [`${t("Enroute")} - ${enroute}`, enroute],
        [`${t("Rejected")} - ${rejected}`, rejected],
        [`${t("Accepted")} - ${accepted}`, accepted],
        [`${t("InvalidNumber")} - ${invalidNumber}`, invalidNumber],
        [`${t("Processing")} - ${processing}`, processing],
        [`${t("Waiting")} - ${waiting}`, waiting],
        [`${t("Unknown")} - ${unknown}`, unknown],
        [`${t("Expired")} - ${expired}`, expired],
        [`${t("NotSent")} - ${notSent}`, notSent],
      ]);

      const qtyOtherStates = 
        undelivery + 
        scheduled + 
        enroute + 
        rejected + 
        accepted + 
        unknown + 
        invalidNumber + 
        processing + 
        waiting + 
        notSent +
        expired;

      setDailyTotalSMS(total);
      setDailyDeliveredSMS(delivery);
      setDailyUndeliveredSMS(qtyOtherStates);

      setGraphSmsDeliveredNotDelivered([
        ["Status", "Quantity"],
        [`${t("Delivered")} - ${delivery}`, delivery],
        [`${t("Undelivered")} - ${qtyOtherStates}`, qtyOtherStates],
      ]);
    }
  }

  const handleSmsMonthlyGraph = async () => {
    let listParams = {
      company_identifier: companyIdentifier,
      actual_date: new Date(),
    };

    const response = await listMonthlyInitialSmsStatus(companyIdentifier, listParams)

    const cost = response?.cost.toFixed(3) ?? 0;

    setGraphSmsMonthlyCost(cost);
  }

  const handleSmsYearlyGraph = async () => {
    let listParams = {
      company_identifier: companyIdentifier,
      actual_date: new Date(),
    };

    const response = await listYearlyInitialSmsStatus(companyIdentifier, listParams);

    setGraphSmsYearly(response);

    const cost = response?.map((item: any) => item.cost).reduce((a: any, b: any) => a + b, 0);

    setGraphSmsYearlyCost(cost.toFixed(3));
  }


  useEffect(() => {
    handleSmsDailyGraph();
    handleSmsMonthlyGraph();
    handleSmsYearlyGraph();
  }, []);

  return (
    <>
      <Breadcrumbs mainTitle={DefaultDashboard} parent={Dashboard} />
      <Container fluid className="default-dashboard">
        <Row>
          <DailyCost data={graphDailyCost} />
          <MonthlyCost data={graphSmsMonthlyCost} />
          <YearlyCost data={graphSmsYearlyCost} />
          <DailyTotalSms data={dailyTotalSMS} />
          <DailyDeliveredSms data={dailyDeliveredSMS} />
          <DailyOtherStatesSms data={dailyUndeliveredSMS} />
          <SmsDaily data={graphSmsDaily} />
          <SmsDailyDeliveredNotDelivered data={graphSmsDeliveredNotDelivered} />
          <YearlySmsMessagesSent data={graphSmsYearly} />
          <YearlySmsSpend data={graphSmsYearly} />
        </Row>
      </Container>
    </>
  );
};

export default ContainerDefault;
