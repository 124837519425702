import { useEffect, useState } from "react";
import { LI, P } from "../../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { viewBalance } from "../../../../Service/balance";
import { useNavigate } from "react-router-dom";

const BalanceHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [balance, setBalance] = useState<any>(0);

  const handleBalance = () => {
    const userData = localStorage.getItem("M2C:userData");

    if (!userData) {
      navigate(`${process.env.PUBLIC_URL}`);
    }

    const companyIdentifier = userData
      ? JSON.parse(userData)?.userInfo?.company_identifier
      : null;

    const userIdentifier = userData ? JSON.parse(userData)?.userInfo?.identifier : null;

    if (!companyIdentifier) {
      return;
    }

    viewBalance(companyIdentifier, {
      identifier: userIdentifier,
      company_identifier: companyIdentifier,
    }).then((response) => {
      setBalance(`$ ${response.balance.toFixed(2)}`);
    }).catch((error) => {
      const value = 0;
      setBalance(`$ ${value.toFixed(2)}`);
    });
  }

  useEffect(() => {
    handleBalance();
    
    const intervalId = setInterval(() => {
      handleBalance();
    }, 60000);
  
    return () => clearInterval(intervalId);
  }, []);

  const styleGeneral = {
    color: 'blue',
    fontSize: '16px',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '0',
    fontWeight: 'normal',
  };

  const styleTitle = {
    color: 'black',
    fontSize: '16px',
    fontWeight: '500',
  };

  return (
    <li style={styleGeneral}>
      <div className="d-flex profile-media align-items-center">
        <div className="flex-grow-1">
          <span><span style={styleTitle}>{t('balance')}:</span> {balance}</span>
        </div>
      </div>
    </li>
  );
};

export default BalanceHeader;
