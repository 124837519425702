import React from "react";

interface MonthYearPickerProps {
  label?: string;
  className?: string;
  min?: string;
  max?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const MonthYearPicker: React.FC<MonthYearPickerProps> = ({ 
  label,
  className, 
  min = "2020-01", 
  max = "2500-12", 
  value, 
  onChange 
}) => {
  return (
    <div className={className} style={{ padding: "12px" }}>
      {label && <label>{label}</label>}
      <input
        className="form-control"
        type="month"
        min={min}
        max={max}
        defaultValue={value}
        onChange={(event) => onChange && onChange(event.target.value)}
      />
    </div>
  );
};

export default MonthYearPicker;
