import ContainerGateway from "../../../Component/Configurations/Gateway/Gateway";

const Gateway = () => {
  return (
    <div className="page-body">
      <ContainerGateway />
    </div>
  );
};

export default Gateway;
