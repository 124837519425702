import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const listCompanies = async (
  id?: any,
  parent_id?: any,
  type?: any,
  name?: any,
  status?: any
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/companies/for-company/${id}`,
      {
        params: {
          parentIdentifier: parent_id,
          type,
          name,
          status,
        },
        ...getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error listing companies", error);
    throw error;
  }
};

const listCompaniesForRoot = async (
  id?: any,
  parent_id?: any,
  type?: any,
  name?: any,
  status?: any
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/companies/for-root-company/${id}`,
      {
        params: {
          parentIdentifier: parent_id,
          type,
          name,
          status,
        },
        ...getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error listing companies", error);
    throw error;
  }
};

const listCompaniesForRootForSelect = async (identifier: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/companies/for-root-company/for-select/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error listing companies", error);
    throw error;
  }
}

const listCompaniesForSelect = async (identifier: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/companies/for-company/for-select/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error listing companies", error);
    throw error;
  }
}

const createCompany = async (data: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/company/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating company", error);
    throw error;
  }
};

const updateCompany = async (identifier: string, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/company/${identifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating company", error);
    throw error;
  }
};

const viewCompany = async (identifier: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error viewing company", error);
    throw error;
  }
};

const deleteCompany = async (identifier: string | undefined): Promise<any> => {
  try {
    const response = await axios.delete(
      `${API_URL}/company/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting company", error);
    throw error;
  }
};

const getCompanyTypesByCompanyIdentifier = async (
  identifier: string
): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/types/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error getting company types", error);
    throw error;
  }
};

const getCompaniesByCompanyIdentifier = async (
  identifier: string
): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/company/${identifier}/company`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error getting companies", error);
    throw error;
  }
};

export {
  listCompanies,
  listCompaniesForRoot,
  listCompaniesForRootForSelect,
  listCompaniesForSelect,
  createCompany,
  updateCompany,
  viewCompany,
  deleteCompany,
  getCompanyTypesByCompanyIdentifier,
  getCompaniesByCompanyIdentifier,
};
