import ContainerSMS from "../../../Component/Campaigns/Sms/Sms";

const SMS = () => {
  return (
    <div className="page-body">
      <ContainerSMS />
    </div>
  );
};

export default SMS;
