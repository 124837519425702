import { TabContent } from 'reactstrap'
import FirstTabContent from './FirstTabContent'
import FourTabContent from './FourTabContent'
import SecondTabContent from './SecondTabContent'
import ThirdTabContent from './ThirdTabContent'
import { TodayTaskTabType } from '../../../../Types/Dashborad/Project'

const TodayTaskTabContent = ({tabId}:TodayTaskTabType) => {
  return (
    <TabContent activeTab={tabId}>
      <FirstTabContent />
      <SecondTabContent />
      <ThirdTabContent />
      <FourTabContent />
    </TabContent>
  )
}

export default TodayTaskTabContent