import { Card, CardBody, Col } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, TopProductHeading, Weekly, Yearly } from "../../../../utils/Constant";
import { P } from "../../../../AbstractElements";
import TopProductBody from "./TopProductBody";

const TopProduct = () => {
  return (
    <Col xxl="5" lg="4" className="box-col-4">
      <Card className="product-card">
        <CommonCardHeader headClass="pb-0" title={TopProductHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody>
          <P>According to overall sales, the top 4 this week</P>
          <TopProductBody />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TopProduct;
