import Login from "../../Component/Authentication/Login";

const LoginPage = () => {
  return (
    <div className="page-body">
      <Login />
    </div>
  );
};

export default LoginPage;
