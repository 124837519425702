import { Card, CardBody, Col, Row } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { TotalRevenueHeading } from "../../../../utils/Constant";
import TotalRevenueChart from "./TotalRevenueChart";
import TotalRevenueIncrease from "./TotalRevenueIncrease";

const TotalRevenue = () => {
  return (
    <Col xl="6" lg="9" md="8">
      <Card className="total-revenue">
        <CommonCardHeader headClass="pb-0" title={TotalRevenueHeading} />
        <CardBody className="p-0">
          <Row>
            <TotalRevenueChart />
            <TotalRevenueIncrease />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TotalRevenue;
