import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { TransactionStatusEnum } from "../../../Enums/transaction.status.enum";
import { Badges } from "../../../AbstractElements";
import { deleteCredit, listCredits } from "../../../Service/credit";
import { TransactionSubtypeEnum } from "../../../Enums/transactions.subtype.enum";

const ContainerCredits = () => {
  const { t } = useTranslation();

  const mainTitle = "credits";
  const base = `${process.env.PUBLIC_URL}/financial/credits`;
  const textBase = [{ text: t("credit_text") }];

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const [data, setData] = useState<any>();

  const fetchData = useCallback(async () => {
    try {
      const response = await listCredits(identifier);
      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [identifier]);

  const ExcludeConfirm = (id: string | undefined, transactionCompanyIdentifier: string | undefined) => {
    SweetAlert.fire({
      title: t("alert_title"),
      text: t("alert_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("alert_cancel"),
      confirmButtonText: t("alert_button"),
    }).then(async (result) => {
      if (result.isConfirmed) {
      }
      if (result.isConfirmed) {
        if (!id || !transactionCompanyIdentifier) {
          SweetAlert.fire({
            title: t("alert_confirm_title_error"),
            text: t("alert_confirm_text_error"),
            icon: "error",
          });
          return;
        }

        await deleteCredit(id, transactionCompanyIdentifier)
          .then(() => {
            fetchData();
            SweetAlert.fire({
              title: t("alert_confirm_title"),
              text: t("alert_confirm_text"),
              icon: "success",
            });
          })
          .catch(() => {
            SweetAlert.fire({
              title: t("alert_confirm_title_error"),
              text: t("alert_confirm_text_error"),
              icon: "error",
            });
          });
      }
    });
  };

  const ColumnAction = ({ row }: { row: any }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleItemClick = (action: any, identifier?: string, companyIdentifier?: string) => {
      if (action === "delete") {
        ExcludeConfirm(identifier, companyIdentifier);
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={() => handleItemClick("delete", row.identifier, row.company_identifier)}
          >
            {t("delete")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const StatusChip = ({ status }: any) => {
    switch (status) {
      case TransactionStatusEnum.CREDIT:
        return (
          <Badges className="badge-light-success btn" color="transparant">
            {t("credit")}
          </Badges>
        );
      
      case TransactionStatusEnum.DEBIT:
        return (
          <Badges className="btn-danger btn" color="transparant">
            {t("debit")}
          </Badges>
        );

      default:
        return (
          <Badges className="btn-danger btn" color="transparant">
            {t("debit")}
          </Badges>
        );
    }
  };

  const SubtypeChip = ({ status }: any) => {
    switch (status) {
      case TransactionSubtypeEnum.CREDIT_TRANSFER:
        return (
          <Badges className="graph-color-4 btn" color="transparant">
            {t("credit_transfer")}
          </Badges>
        );

      case TransactionSubtypeEnum.DEPOSIT:
        return (
          <Badges className="graph-color-2 btn" color="transparant">
            {t("deposit")}
          </Badges>
        );

      default:
        return (
          <Badges className="graph-color-4 btn" color="transparant">
            {t("credit_transfer")}
          </Badges>
        );
    }
  };

  const columns = [
    {
      name: t("value"),
      selector: (row: any) => `${row.amount}`,
      sortable: true,
    },
    {
      name: t("description"),
      selector: (row: any) => `${row.description}`,
      sortable: true,
    },
    {
      name: t("type"),
      selector: (row: any) => row.type,
      cell: (row: any) => <StatusChip status={row.type} />,
      sortable: true,
    },
    {
      name: t("subtype"),
      selector: (row: any) => row.type,
      cell: (row: any) => <SubtypeChip status={row.subtype} />,
      sortable: true,
    },
    {
      name: t("document"),
      selector: (row: any) => `${row.company_document}`,
      sortable: true,
    },
    {
      name: t("company"),
      selector: (row: any) => `${row.company_name}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };
  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <CardHeaderCommon title={t("list")} span={textBase} />
                <div className="btn-search-companies">
                  <Button
                    color="primary"
                    tag={Link}
                    to={`${process.env.PUBLIC_URL}/financial/credits/add`}
                  >
                    {t("add_transaction")}
                  </Button>
                </div>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerCredits;
