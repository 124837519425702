import { Card, CardBody, Col, Table } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { ManageOrderHeading, Monthly, Weekly, Yearly } from "../../../../utils/Constant";
import ManageOrderTableHeader from "./ManageOrderTableHeader";
import ManageOrderTableBody from "./ManageOrderTableBody";

const ManageOrder = () => {
  return (
    <Col lg="7" className="box-col-7">
      <Card>
        <CommonCardHeader headClass="pb-0" title={ManageOrderHeading} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} mainTitle={true} />
        <CardBody className="pt-0 report">
          <div className="custom-scrollbar table-responsive">
            <Table className="display" id="report" style={{ width: "100%" }} >
              <ManageOrderTableHeader />
              <ManageOrderTableBody />
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ManageOrder;
