import axios from "axios";
import store from "../ReduxToolkit/Store";
import { ca } from "date-fns/locale";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const listReports = async (companyIdentifier: any, params?: any): Promise<any[]> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/syntetic/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsCampaign = async (companyIdentifier: any, campaignIdentifier: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/syntetic/campaign/${campaignIdentifier}/${companyIdentifier}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsDetailed = async (companyIdentifier: any, params?: any): Promise<any[]> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/detailed/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsDetailedCampaign = async (companyIdentifier: any, campaignIdentifier: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/detailed/campaign/${campaignIdentifier}/${companyIdentifier}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsByRoot = async (companyIdentifier: any, params?: any): Promise<any[]> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/syntetic/by-root/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsCampaignByRoot = async (companyIdentifier: any, campaignIdentifier: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/syntetic/campaign/by-root/${campaignIdentifier}/${companyIdentifier}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsDetailedByRoot = async (companyIdentifier: any, params?: any): Promise<any[]> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/detailed/by-root/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const listReportsDetailedCampaignByRoot = async (companyIdentifier: any, campaignIdentifier: any): Promise<any[]> => {
  try {
    const response = await axios.get<any[]>(
      `${API_URL}/sms-reports/detailed/campaign/by-root/${campaignIdentifier}/${companyIdentifier}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};

const createTransaction = async (data: any): Promise<any> => {
  try {
    const response = await axios.post<any>(
      `${API_URL}/transactions`,
      data,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    console.error("Error creating transaction", error);
    throw error;
  }
};

const listInitialSmsStatus = async (companyIdentifier: any, params?: any): Promise<any> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any>(
      `${API_URL}/sms-reports/syntetic/daily/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
}

const listMonthlyInitialSmsStatus = async (companyIdentifier: any, params?: any): Promise<any> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any>(
      `${API_URL}/sms-reports/syntetic/monthly/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
}

const listYearlyInitialSmsStatus = async (companyIdentifier: any, params?: any): Promise<any> => {
  try {
    let urlParams = '';

    if (params) {
      urlParams = '?';

      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null) {
          if (index === 0) {
            urlParams += `${key}=${params[key]}`;
          } else {
            urlParams += `&${key}=${params[key]}`;
          }
        }
      });
    }

    const response = await axios.get<any>(
      `${API_URL}/sms-reports/syntetic/yearly/${companyIdentifier}${urlParams}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
}


export { 
  listReports, 
  listReportsCampaign,
  listReportsByRoot,
  listReportsCampaignByRoot, 
  listInitialSmsStatus,
  listMonthlyInitialSmsStatus,
  listYearlyInitialSmsStatus,
  createTransaction, 
  listReportsDetailedByRoot,
  listReportsDetailedCampaignByRoot,
  listReportsDetailed,
  listReportsDetailedCampaign,
};
