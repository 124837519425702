import { dynamicImage } from "../../../../Service";
import { upcomingDeadlinesBodyData } from "../../../../Data/Dashboard/Project/Project";
import { H6, Image, Progressbar } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { Href } from "../../../../utils/Constant";

const UpcomingDeadlinesBody = () => {
  return (
    <tbody>
      {upcomingDeadlinesBodyData.map((data, i) => (
        <tr key={i}>
          <td>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <Image src={dynamicImage(`dashboard-3/user/${data.image}`)} alt="user" />
              </div>
              <div className="flex-grow-1 ms-2">
                <Link to={Href}>
                  <H6>{data.name}</H6>
                </Link>
              </div>
            </div>
          </td>
          <td>
            <span>{data.task}</span>
          </td>
          <td>{data.deadLine}</td>
          <td>
            <Progressbar value={data.value} color={data.color} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default UpcomingDeadlinesBody;
