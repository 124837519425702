import { Col } from "reactstrap";
import { Btn, H2 } from "../../../../AbstractElements";
import ReactApexChart from "react-apexcharts";
import { monthlyChart } from "../../../../Data/Dashboard/DashboardChart/DashboardChart";
import { Increase } from "../../../../utils/Constant";

const TotalRevenueIncrease = () => {
  return (
    <Col lg="3" className="increase ps-0">
      <div className="d-flex pe-3">
        <span className="me-2">Year:</span>
        <span>
          2024
          <i className="fa fa-caret-down ms-2" />
        </span>
      </div>
      <div className="total-increase">
        <H2>$25,837</H2>
        <span>Total : 23,000</span>
      </div>
      <ReactApexChart options={monthlyChart} series={monthlyChart.series} height={100} type="area" />
      <Btn color="primary">{Increase}</Btn>
    </Col>
  );
};

export default TotalRevenueIncrease;
