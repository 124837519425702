import SmsReportsDetailsCampaign from "../../../Component/Campaigns/Sms/SmsReportsDetailsCampaign";

const SMSReportsCampaign = () => {
  return (
    <div className="page-body">
      <SmsReportsDetailsCampaign />
    </div>
  );
};

export default SMSReportsCampaign;
