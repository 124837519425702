import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import Login from "../Component/Authentication/Login";
import ForgetPassword from "../Component/Authentication/ForgetPassword";

const RouterData = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  const isLoggedIn = userData?.isLoggedIn;

  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route
              path={`${process.env.PUBLIC_URL}` || "/"}
              element={
                <Navigate to={`${process.env.PUBLIC_URL}/campaigns/sms`} />
              }
            />
          </>
        ) : (
          <>
            <Route
              path={`${process.env.PUBLIC_URL}` || "/"}
              element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
            />
          </>
        )}
        <Route path={"/"} element={<PrivateRoute />}>
          <Route path={`/*`} element={<LayoutRoutes />} />
        </Route>
        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route
          path={`${process.env.PUBLIC_URL}/ForgetPassword`}
          element={<ForgetPassword />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
