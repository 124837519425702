import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import FormValidations from "./ValidationsForm";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { createUser, updateUser, viewUser } from "../../../Service/users";

const ContainerUsersAdd = () => {
  const { t } = useTranslation();
  const base = `${process.env.PUBLIC_URL}/configurations/companies`;
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { id, company_id } = useParams<any>();
  const mainTitle = id ? t("edit_user_text") : t("add_user_text");
  const navigate = useNavigate();

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      password: null,
      email: null,
      mobile_phone: null,
      first_name: null,
      last_name: null,
      type: "",
      active: false,
      company_identifier: company_id,
      use_api: false,
      service_identifier: null,
    });

  const viewUserData = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        const userData: any = await viewUser(id, identifier);
        setValidationFormInitialValue({
          password: "",
          email: userData.email,
          mobile_phone: userData.mobile_phone,
          first_name: userData.first_name,
          last_name: userData.last_name,
          type: userData.type,
          active: userData.active,
          company_identifier: company_id,
          use_api: userData.use_api,
          service_identifier: userData.service_identifier,
        });
      } catch (error) {
        console.error("Error fetching user data", error);
        toast.error(t("error_loading_user_data"));
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [id, identifier, t]);

  const handleSubmit = async (
    values: any,
    { setValues }: { resetForm: () => void; setValues: (values: any) => void }
  ) => {
    setLoading(true);
    try {
      const sendData: any = {
        email: values.email,
        mobile_phone: values.mobile_phone,
        first_name: values.first_name,
        last_name: values.last_name,
        type: values.type,
        active: values.active,
        company_identifier: company_id,
        use_api: values.use_api,
        service_identifier: values.service_identifier,
      };

      if (id) {
        await updateUser(id, identifier, sendData);
      } else {
        sendData.password = values.password;
        await createUser(identifier, sendData);
      }

      setValues(validationFormInitialValue);
      toast.success(t("success_saved"));
      setSubmitError(false);
      navigate(
        `${process.env.PUBLIC_URL}/configurations/companies/users/${company_id}`
      );
    } catch (error: any) {
      console.error("Error saving user", error);
      setSubmitError(true);

      let message  = t("error_saved");

      if (error.response?.data?.message === "User already exists") {
        message = t("error_user_already_exists");
      }

      const errorMessage = message;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const formValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("first_name_alert_user")),
    last_name: Yup.string().required(t("last_name_alert_user")),
    email: Yup.string().required(t("email_alert_user")),
    mobile_phone: Yup.string().required(t("phone_alert_user")),
    type: Yup.string().required(t("type_alert_user")),
  });

  useEffect(() => {
    viewUserData();
  }, [viewUserData]);

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={validationFormInitialValue}
                  onSubmit={handleSubmit}
                  validationSchema={formValidationSchema}
                >
                  {({ errors }) => (
                    <FormValidations
                      submitErrors={submitErrors}
                      setSubmitError={setSubmitError}
                      errors={errors}
                      loading={loading}
                      userId={id}
                    />
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerUsersAdd;
