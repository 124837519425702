import { Link } from "react-router-dom";
import { H6, Image, LI, P, UL } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { trackOrderStatusData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";

const TrackOrderStatus = () => {
  return (
    <UL className="track-order simple-list">
      {trackOrderStatusData.map((data, i) => (
        <LI key={i}>
          <div className="d-flex">
            <div className={`flex-shrink-0 bg-${data.color}`}>
              <Image src={dynamicImage(`dashboard-2/product/${data.image}`)}  alt="product" />
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between">
                <Link to="product.html">
                  <H6>{data.title}</H6>
                  <P className="text-truncate">{data.detail}</P>
                </Link>
                <span className={`font-${data.color} f-w-500`}>{data.date}</span>
              </div>
            </div>
          </div>
        </LI>
      ))}
    </UL>
  );
};

export default TrackOrderStatus;
