import { H5, H6, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";

const TrackOrderPlace = () => {
  return (
    <div className="order-place">
      <div className="d-flex">
        <div className="flex-shrink-0">
          <Image
            src={dynamicImage(`dashboard-2/product/headphone.png`)}
            alt="product"
          />
        </div>
        <div className="flex-grow-1">
          <Link to={`${process.env.PUBLIC_URL}/ecommerce/products`}>
            <H6 className="f-w-500">Wired Headphones with Mic (Black)</H6>
            <H5 className="font-primary f-w-600 mt-1">$27.93</H5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrackOrderPlace;
