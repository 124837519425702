import { Btn, H6, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";
import { manageOrderTableBodyData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";
import { Input, Label } from "reactstrap";

const ManageOrderTableBody = () => {
  return (
    <tbody>
      {manageOrderTableBodyData.map((data, i) => (
        <tr key={i}>
          <td>
            <div className="form-check">
              <Input type="checkbox" />
              <Label />
            </div>
          </td>
          <td>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <Image src={dynamicImage(`dashboard-2/product/${data.image}`)} alt="product"/>
              </div>
              <div className="flex-grow-1 ms-3">
                <Link to={`${process.env.PUBLIC_URL}/ecommerce/productlist`}>
                  <H6>{data.productName}</H6>
                </Link>
              </div>
            </div>
          </td>
          <td>#{data.id}</td>
          <td>{data.date}</td>
          <td>${data.price}</td>
          <td>
            <Btn color={data.color}>{data.status}</Btn>
          </td>
          <td>{data.quantity} PCS</td>
        </tr>
      ))}
    </tbody>
  );
};

export default ManageOrderTableBody;
