import ContainerCreditsAdd from "../../../Component/Financial/Credits/CreditsAdd";

const CreditsAdd = () => {
  return (
    <div className="page-body">
      <ContainerCreditsAdd />
    </div>
  );
};

export default CreditsAdd;
