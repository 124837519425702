import Bookmark from "../Pages/Application/Bookmark/Bookmark";
import Calender from "../Pages/Application/Calender/Calender";
import GroupChat from "../Pages/Application/Chat/GroupChat/GroupChat";
import PrivateChat from "../Pages/Application/Chat/PrivateChat/PrivateChat";
// import Contacts from "../Pages/Application/Contacts/Contacts";
import AddProduct from "../Pages/Application/Ecommerce/AddProduct/AddProduct";
import Cart from "../Pages/Application/Ecommerce/Cart/Cart";
import Checkout from "../Pages/Application/Ecommerce/Checkout/Checkout";
import InvoiceOne from "../Pages/Application/Ecommerce/Invoices/Invoice-1/Invoice-1";
import InvoiceTwo from "../Pages/Application/Ecommerce/Invoices/Invoice-2/Invoice-2";
import InvoiceThree from "../Pages/Application/Ecommerce/Invoices/Invoice-3/Invoice-3";
import InvoiceFour from "../Pages/Application/Ecommerce/Invoices/Invoice-4/Invoice-4";
import InvoiceFive from "../Pages/Application/Ecommerce/Invoices/Invoice-5/Invoice-5";
import InvoiceSix from "../Pages/Application/Ecommerce/Invoices/Invoice-6/Invoice-6";
import OrderHistory from "../Pages/Application/Ecommerce/OrderHistory/OrderHistory";
import PaymentDetails from "../Pages/Application/Ecommerce/PaymentDetails/PaymentDetails";
import Pricing from "../Pages/Application/Ecommerce/Pricing/Pricing";
import ProductList from "../Pages/Application/Ecommerce/ProductList/ProductList";
import ProductPage from "../Pages/Application/Ecommerce/ProductPage/ProductPage";
import Products from "../Pages/Application/Ecommerce/Products/Products";
import WishList from "../Pages/Application/Ecommerce/WishList/WishList";
import LetterBox from "../Pages/Application/Email/LetterBox/LetterBox";
import FileManager from "../Pages/Application/FileManager/FileManager";
import ProjectCreate from "../Pages/Application/Project/ProjectCreate/ProjectCreate";
import ProjectList from "../Pages/Application/Project/ProjectList/ProjectList";
import SearchResult from "../Pages/Application/SearchResult/SearchResult";
import SocialApp from "../Pages/Application/SocialApp/SocialApp";
import Tasks from "../Pages/Application/Tasks/Tasks";
import Todo from "../Pages/Application/Todo/Todo";
import EditProfile from "../Pages/Application/Users/EditProfile/EditProfile";
import UserCards from "../Pages/Application/Users/UserCards/UserCards";
import UsersProfile from "../Pages/Application/Users/UsersProfile/UsersProfile";
import BasicCard from "../Pages/BonusUi/BasicCard/BasicCard";
import Breadcrumb from "../Pages/BonusUi/Breadcrumb/Breadcrumb";
import CreativeCard from "../Pages/BonusUi/CreativeCard/CreativeCard";
import Dropzone from "../Pages/BonusUi/Dropzone/Dropzone";
import ImageCropper from "../Pages/BonusUi/ImageCropper/ImageCropper";
import OwlCarousel from "../Pages/BonusUi/OwlCarousel/OwlCarousel";
import Pagination from "../Pages/BonusUi/Paginations/Paginations";
import RangeSlider from "../Pages/BonusUi/RangeSlider/RangeSlider";
import Rating from "../Pages/BonusUi/Rating/Rating";
import Ribbons from "../Pages/BonusUi/Ribbons/Ribbons";
import Scrollable from "../Pages/BonusUi/Scrollable/Scrollable";
import SweetAlert from "../Pages/BonusUi/SweetAlert/SweetAlert";
import Timeline from "../Pages/BonusUi/Timeline/Timeline";
import Toast from "../Pages/BonusUi/Toast/Toast";
import Tour from "../Pages/BonusUi/Tour/Tour";
import TreeView from "../Pages/BonusUi/TreeView/TreeView";
import ButtonGroup from "../Pages/Buttons/ButtonGroup/ButtonGroup";
import DefaultStyle from "../Pages/Buttons/DefaultStyle/DefaultStyle";
import ApexChart from "../Pages/Charts/Apexchart/ApexChart";
import ChartJs from "../Pages/Charts/ChartJs/ChartJs";
import GoogleChart from "../Pages/Charts/GoogleChart/GoogleChart";
import Default from "../Pages/Dashboard/Default/Default";
import Ecommerce from "../Pages/Dashboard/Ecommerce/Ecommerce";
import Project from "../Pages/Dashboard/Project/Project";
import BaseInput from "../Pages/Forms/FormsControl/BaseInput/BaseInput";
import FormsValidation from "../Pages/Forms/FormsControl/FormsValidation/FormsValidation";
import InputGroup from "../Pages/Forms/FormsControl/InputGroup/InputGroup";
import InputMask from "../Pages/Forms/FormsControl/InputMask/InputMask";
import MegaOption from "../Pages/Forms/FormsControl/MegaOption/MegaOption";
import RadioCheckbox from "../Pages/Forms/FormsControl/RadioCheckbox/RadioCheckbox";
import FormWizardOne from "../Pages/Forms/FormsLayout/FormWizardOne/FormWizardOne";
import FormWizardTwo from "../Pages/Forms/FormsLayout/FormWizardTwo/FormWizardTwo";
import TwoFactor from "../Pages/Forms/FormsLayout/TwoFactor/TwoFactor";
import ClipBoard from "../Pages/Forms/FormsWidgets/ClipBoard/ClipBoard";
import DatePicker from "../Pages/Forms/FormsWidgets/DatePicker/DatePicker";
import Switch from "../Pages/Forms/FormsWidgets/Switch/Switch";
import TouchSpin from "../Pages/Forms/FormsWidgets/TouchSpin/TouchSpin";
import Typeahead from "../Pages/Forms/FormsWidgets/Typeahead/Typeahead";
import FeatherIcons from "../Pages/Icons/FeatherIcons";
import FlagIcons from "../Pages/Icons/FlagIcons";
import FontAwesomeIcon from "../Pages/Icons/FontAwesomeIcon";
import ICOIcon from "../Pages/Icons/ICOIcon";
import ThemifyIcons from "../Pages/Icons/ThemifyIcons";
import WhetherIcon from "../Pages/Icons/WhetherIcons";
import AddPost from "../Pages/Miscellaneous/Blog/AddPost/AddPost";
import BlogDetails from "../Pages/Miscellaneous/Blog/BlogDetails/BlogDetails";
import BlogSingle from "../Pages/Miscellaneous/Blog/BlogSingle/BlogSingle";
import AceEditor from "../Pages/Miscellaneous/Editor/AceEditor/AceEditor";
import CkEditor from "../Pages/Miscellaneous/Editor/CkEditor/CkEditor";
import MdeEditor from "../Pages/Miscellaneous/Editor/MdeEditor/MdeEditor";
import Faq from "../Pages/Miscellaneous/Faq/Faq";
import GalleryGrid from "../Pages/Miscellaneous/Gallery/GalleryGrid/GalleryGrid";
import GalleryGridDesc from "../Pages/Miscellaneous/Gallery/GalleryGridDesc/GalleryGridDesc";
import HoverEffect from "../Pages/Miscellaneous/Gallery/HoverEffect/HoverEffect";
import MasonryGallery from "../Pages/Miscellaneous/Gallery/MasonryGallery/MasonryGallery";
import MasonryImageDesc from "../Pages/Miscellaneous/Gallery/MasonryImageDesc/MasonryImageDesc";
import CardView from "../Pages/Miscellaneous/JobSearch/CardView/CardView";
import JobApply from "../Pages/Miscellaneous/JobSearch/JobApply/JobApply";
import JobDetails from "../Pages/Miscellaneous/JobSearch/JobDetails/JobDetails";
import ListView from "../Pages/Miscellaneous/JobSearch/ListView/ListView";
import Knowledgebase from "../Pages/Miscellaneous/Knowledgebase/Knowledgebase";
import DetailsCourse from "../Pages/Miscellaneous/Learning/DetailsCourse/DetailsCourse";
import LearningList from "../Pages/Miscellaneous/Learning/LearningList/LearningList";
import GoogleMap from "../Pages/Miscellaneous/Maps/GoogleMap/GoogleMap";
import LeafletMaps from "../Pages/Miscellaneous/Maps/LeafletMaps/LeafletMaps";
import SupportTicket from "../Pages/Miscellaneous/SupportTicket/SupportTicket";
import HideNavScrollContainer from "../Pages/PageLayout/HideNavScrollContainer/HideNavScrollContainer";
import SamplePage from "../Pages/SamplePage/SamplePage";
import AdvanceInit from "../Pages/Tables/DataTables/AdvanceInit/AdvanceInit";
import ApiDataTable from "../Pages/Tables/DataTables/ApiDataTable/ApiDataTable";
import BasicInit from "../Pages/Tables/DataTables/BasicInit/BasicInit";
import DataSource from "../Pages/Tables/DataTables/DataSource/DataSource";
import BasicTable from "../Pages/Tables/ReactstrapTable/BasicTable/BasicTable";
import TableComponent from "../Pages/Tables/ReactstrapTable/TableComponent/TableComponent";
import Accordion from "../Pages/Ui-Kits/Accordion/Accordion";
import Alert from "../Pages/Ui-Kits/Alert/Alert";
import Avatars from "../Pages/Ui-Kits/Avatars";
import Dropdown from "../Pages/Ui-Kits/Dropdown/Dropdown";
import Grid from "../Pages/Ui-Kits/Grid/Grid";
import HelperClasses from "../Pages/Ui-Kits/HelperClasses";
import Lists from "../Pages/Ui-Kits/Lists/Lists";
import Modal from "../Pages/Ui-Kits/Modal/Modal";
import Popover from "../Pages/Ui-Kits/Popover/Popover";
import Progress from "../Pages/Ui-Kits/Progress/Progress";
import ReactstrapTabs from "../Pages/Ui-Kits/Tabs/ReactstrapTabs";
import TagAndPills from "../Pages/Ui-Kits/TagAndPills/TagAndPills";
import Tooltip from "../Pages/Ui-Kits/Tooltip/Tooltip";
import Typography from "../Pages/Ui-Kits/Typography";
import Charts from "../Pages/Widgets/Charts/Charts";
import General from "../Pages/Widgets/General/General";
import Contact from "../Pages/Campaigns/Contact/Contact";
import File from "../Pages/Campaigns/File/File";
import Messages from "../Pages/Campaigns/Messages/Messages";
import Single from "../Pages/Campaigns/Single/Single";
import Services from "../Pages/Configurations/Services/Services";
import Users from "../Pages/Configurations/Users/Users";
import UsersAdd from "../Pages/Configurations/Users/UsersAdd";
import BlackList from "../Pages/Contacts/BlackList/BlackList";
// import ContactList from "../Pages/Contacts/ContactList/ContactList";
// import Contacts from "../Pages/Contacts/Contacts/Contacts";
import Procon from "../Pages/Contacts/Procon/Procon";
import Gateway from "../Pages/Configurations/Gateway/Gateway";
import GatewayAdd from "../Pages/Configurations/Gateway/GatewayAdd";
import SMS from "../Pages/Campaigns/Sms/Sms";
import SMSAccess from "../Pages/Campaigns/SmsAccess/SmsAccess";
import SMSReports from "../Pages/Campaigns/SmsReports/SmsReports";
import LoginPage from "../Pages/Auth/Login";
import BlacklistContacts from "../Pages/Contacts/BlacklistContacts/BlacklistContacts";
import Transactions from "../Pages/Financial/Transactions/Transactions";
import Balance from "../Pages/Financial/Balance/Balance";
import TransactionsAdd from "../Pages/Financial/Transactions/TransactionsAdd";
import SMSReportsDetails from "../Pages/Campaigns/SmsReportsDetails/SmsReportsDetails";
import Companies from "../Pages/Configurations/Companies/Companies";
import CompaniesAdd from "../Pages/Configurations/Companies/CompaniesAdd";
import Credits from '../Pages/Financial/Credits/Credits';
import CreditsAdd from "../Pages/Financial/Credits/CreditsAdd";
import PosPaid from "../Pages/Financial/PosPaid/PosPaid";
import SMSReportsCampaign from "../Pages/Campaigns/SmsReportsCampaign/SmsReportsCampaign";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}`,
    Component: <LoginPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/`,
    Component: <LoginPage />,
  },
  // contacts
  {
    path: `${process.env.PUBLIC_URL}/contact/blacklist`,
    Component: <BlackList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contact/backlist/contacts/:id/:name`,
    Component: <BlacklistContacts />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/contact/contactlist`,
  //   Component: <ContactList />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/contact/contacts/:id/:name`,
  //   Component: <Contacts />,
  // },
  { path: `${process.env.PUBLIC_URL}/contact/procon`, Component: <Procon /> },

  // campaigns
  { path: `${process.env.PUBLIC_URL}/campaigns/sms`, Component: <SMS /> },
  {
    path: `${process.env.PUBLIC_URL}/campaigns/sms/access/:id`,
    Component: <SMSAccess />,
  },
  {
    path: `${process.env.PUBLIC_URL}/campaigns/sms/reports/:id`,
    Component: <SMSReports />,
  },
  {
    path: `${process.env.PUBLIC_URL}/campaigns/sms/reports/campaign/:campaign_identifier/:company_identifier`,
    Component: <SMSReportsCampaign />,
  },
  {
    path: `${process.env.PUBLIC_URL}/campaigns/contact`,
    Component: <Contact />,
  },
  { path: `${process.env.PUBLIC_URL}/campaigns/file`, Component: <File /> },
  {
    path: `${process.env.PUBLIC_URL}/campaigns/messages`,
    Component: <Messages />,
  },
  { path: `${process.env.PUBLIC_URL}/campaigns/single`, Component: <Single /> },
  { path: `${process.env.PUBLIC_URL}/campaigns/sms/reports`, Component: <SMSReports /> },
  { path: `${process.env.PUBLIC_URL}/campaigns/sms/reports/details`, Component: <SMSReportsDetails /> },

  //Clients
  {
    path: `${process.env.PUBLIC_URL}/companies`,
    Component: <Companies />,
  },
  {
    path: `${process.env.PUBLIC_URL}/companies/add`,
    Component: <CompaniesAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/companies/edit/:id`,
    Component: <CompaniesAdd />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/clients/credits/:id`,
  //   Component: <Credits />,
  // },

  // Configurations
  {
    path: `${process.env.PUBLIC_URL}/configurations/services`,
    Component: <Services />,
  },
  {
    path: `${process.env.PUBLIC_URL}/configurations/gateway`,
    Component: <Gateway />,
  },
  {
    path: `${process.env.PUBLIC_URL}/configurations/gateway/add`,
    Component: <GatewayAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/configurations/gateway/edit/:id`,
    Component: <GatewayAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/configurations/companies/users/:id`,
    Component: <Users />,
  },
  {
    path: `${process.env.PUBLIC_URL}/configurations/companies/users/add/:company_id`,
    Component: <UsersAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/configurations/companies/users/edit/:id/:company_id`,
    Component: <UsersAdd />,
  },

  // financial
  {
    path: `${process.env.PUBLIC_URL}/financial/transactions`,
    Component: <Transactions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/financial/transactions/add`,
    Component: <TransactionsAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/financial/balance`,
    Component: <Balance />,
  },
  {
    path: `${process.env.PUBLIC_URL}/financial/credits`,
    Component: <Credits />,
  },
  {
    path: `${process.env.PUBLIC_URL}/financial/credits/add`,
    Component: <CreditsAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/financial/pos-paid`,
    Component: <PosPaid />,
  },

  // dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/project`,
    Component: <Project />,
  },

  // Widgets
  { path: `${process.env.PUBLIC_URL}/widgets/general`, Component: <General /> },
  { path: `${process.env.PUBLIC_URL}/widgets/chart`, Component: <Charts /> },

  // Page Layout
  {
    path: `${process.env.PUBLIC_URL}/pagelayout/hidenavscroll`,
    Component: <HideNavScrollContainer />,
  },

  // Applications
  {
    path: `${process.env.PUBLIC_URL}/project/projectlist`,
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/createnew`,
    Component: <ProjectCreate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/filemanager`,
    Component: <FileManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/letterbox`,
    Component: <LetterBox />,
  },
  {
    path: `${process.env.PUBLIC_URL}/chat/privatechats`,
    Component: <PrivateChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/chat/groupchat`,
    Component: <GroupChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/userprofile`,
    Component: <UsersProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/useredit`,
    Component: <EditProfile />,
  },
  { path: `${process.env.PUBLIC_URL}/users/cards`, Component: <UserCards /> },
  { path: `${process.env.PUBLIC_URL}/app/bookmark`, Component: <Bookmark /> },
  // { path: `${process.env.PUBLIC_URL}/app/contacts`, Component: <Contacts /> },
  { path: `${process.env.PUBLIC_URL}/app/calender`, Component: <Calender /> },
  { path: `${process.env.PUBLIC_URL}/app/task`, Component: <Tasks /> },
  { path: `${process.env.PUBLIC_URL}/app/socialapp`, Component: <SocialApp /> },
  { path: `${process.env.PUBLIC_URL}/app/todo`, Component: <Todo /> },
  {
    path: `${process.env.PUBLIC_URL}/app/searchresult`,
    Component: <SearchResult />,
  },

  // Ecommerce
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/addproduct`,
    Component: <AddProduct />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/products`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productpage`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productlist`,
    Component: <ProductList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice/invoice1`,
    Component: <InvoiceOne />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice/invoice2`,
    Component: <InvoiceTwo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice/invoice3`,
    Component: <InvoiceThree />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice/invoice4`,
    Component: <InvoiceFour />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice/invoice5`,
    Component: <InvoiceFive />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice/invoice6`,
    Component: <InvoiceSix />,
  },
  { path: `${process.env.PUBLIC_URL}/ecommerce/cart`, Component: <Cart /> },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`,
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/checkout`,
    Component: <Checkout />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/pricing`,
    Component: <Pricing />,
  },

  // Form Controls
  {
    path: `${process.env.PUBLIC_URL}/forms/formscontrols/formvalidation`,
    Component: <FormsValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formscontrols/baseinput`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formscontrols/radiocheckbox`,
    Component: <RadioCheckbox />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formscontrols/inputgroups`,
    Component: <InputGroup />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formscontrols/inputmask`,
    Component: <InputMask />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formscontrols/megaoption`,
    Component: <MegaOption />,
  },

  // Forms Widgets
  {
    path: `${process.env.PUBLIC_URL}/forms/formswidget/datepicker`,
    Component: <DatePicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formswidget/touchspin`,
    Component: <TouchSpin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formswidget/switch`,
    Component: <Switch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formswidget/typeahead`,
    Component: <Typeahead />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formswidget/clipboard`,
    Component: <ClipBoard />,
  },

  // Form Layout
  {
    path: `${process.env.PUBLIC_URL}/forms/formslayout/formwizard1`,
    Component: <FormWizardOne />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formslayout/formwizard2`,
    Component: <FormWizardTwo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formslayout/twofactor`,
    Component: <TwoFactor />,
  },

  // Tables
  {
    path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
    Component: <BasicTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/reactstraptable/tablecomponent`,
    Component: <TableComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/datatable/basicinit`,
    Component: <BasicInit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/datatable/advanceinit`,
    Component: <AdvanceInit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/datatable/api`,
    Component: <ApiDataTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/datatable/datasources`,
    Component: <DataSource />,
  },

  // Ui-Kits
  {
    path: `${process.env.PUBLIC_URL}/uikits/typography`,
    Component: <Typography />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/avatars`, Component: <Avatars /> },
  {
    path: `${process.env.PUBLIC_URL}/uikits/helperclass`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/grid`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/uikits/tagpills`,
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/uikits/progress`,
    Component: <Progress />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/modal`, Component: <Modal /> },
  { path: `${process.env.PUBLIC_URL}/uikits/alert`, Component: <Alert /> },
  { path: `${process.env.PUBLIC_URL}/uikits/popover`, Component: <Popover /> },
  { path: `${process.env.PUBLIC_URL}/uikits/tooltip`, Component: <Tooltip /> },
  {
    path: `${process.env.PUBLIC_URL}/uikits/dropdown`,
    Component: <Dropdown />,
  },
  {
    path: `${process.env.PUBLIC_URL}/uikits/accordion`,
    Component: <Accordion />,
  },
  {
    path: `${process.env.PUBLIC_URL}/uikits/tabs`,
    Component: <ReactstrapTabs />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/list`, Component: <Lists /> },

  // Bonus Ui
  {
    path: `${process.env.PUBLIC_URL}/bonusui/scrollable`,
    Component: <Scrollable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/treeview`,
    Component: <TreeView />,
  },
  { path: `${process.env.PUBLIC_URL}/bonusui/toasts`, Component: <Toast /> },
  { path: `${process.env.PUBLIC_URL}/bonusui/rating`, Component: <Rating /> },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/dropzone`,
    Component: <Dropzone />,
  },
  { path: `${process.env.PUBLIC_URL}/bonusui/tour`, Component: <Tour /> },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/sweetalert2`,
    Component: <SweetAlert />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/owlcarousel`,
    Component: <OwlCarousel />,
  },
  { path: `${process.env.PUBLIC_URL}/bonusui/ribbons`, Component: <Ribbons /> },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/pagination`,
    Component: <Pagination />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/breadcrumb`,
    Component: <Breadcrumb />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/rangeslider`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/imagecropper`,
    Component: <ImageCropper />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/basiccards`,
    Component: <BasicCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/creativecards`,
    Component: <CreativeCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/timeline`,
    Component: <Timeline />,
  },

  // Buttons
  {
    path: `${process.env.PUBLIC_URL}/buttons/defaultstyle`,
    Component: <DefaultStyle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/buttongroup`,
    Component: <ButtonGroup />,
  },

  // Icons
  {
    path: `${process.env.PUBLIC_URL}/icons/flagicons`,
    Component: <FlagIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesomeicon`,
    Component: <FontAwesomeIcon />,
  },
  { path: `${process.env.PUBLIC_URL}/icons/icoicon`, Component: <ICOIcon /> },
  {
    path: `${process.env.PUBLIC_URL}/icons/themifyicon`,
    Component: <ThemifyIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feathericon`,
    Component: <FeatherIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/whethericon`,
    Component: <WhetherIcon />,
  },

  // Charts
  {
    path: `${process.env.PUBLIC_URL}/charts/apexchart`,
    Component: <ApexChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/googlechart`,
    Component: <GoogleChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartjschart`,
    Component: <ChartJs />,
  },

  // Sample Page
  {
    path: `${process.env.PUBLIC_URL}/pages/samplepage`,
    Component: <SamplePage />,
  },

  // Gallery
  {
    path: `${process.env.PUBLIC_URL}/gallery/gallerygrids`,
    Component: <GalleryGrid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/gallerygriddesc`,
    Component: <GalleryGridDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/masonrygallery`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/masonrywithdesc`,
    Component: <MasonryImageDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/hovereffect`,
    Component: <HoverEffect />,
  },

  // Blog
  {
    path: `${process.env.PUBLIC_URL}/blog/blogdetails`,
    Component: <BlogDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blogsingle`,
    Component: <BlogSingle />,
  },
  { path: `${process.env.PUBLIC_URL}/blog/addpost`, Component: <AddPost /> },

  // Faq
  { path: `${process.env.PUBLIC_URL}/faq/faq`, Component: <Faq /> },

  // Job Search
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/cardview`,
    Component: <CardView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/listview`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/jobdetail`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/apply`,
    Component: <JobApply />,
  },

  // Learning
  {
    path: `${process.env.PUBLIC_URL}/learning/learninglist`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/learning/detailcourse`,
    Component: <DetailsCourse />,
  },

  // Maps
  { path: `${process.env.PUBLIC_URL}/map/googlemap`, Component: <GoogleMap /> },
  {
    path: `${process.env.PUBLIC_URL}/map/leafletmap`,
    Component: <LeafletMaps />,
  },

  // Editors
  {
    path: `${process.env.PUBLIC_URL}/editor/ckeditor`,
    Component: <CkEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeeditor`,
    Component: <MdeEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/aceeditor`,
    Component: <AceEditor />,
  },

  // Knowledgebase
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebase`,
    Component: <Knowledgebase />,
  },

  // SupportTicket
  {
    path: `${process.env.PUBLIC_URL}/supportticket/supportticket`,
    Component: <SupportTicket />,
  },
];
