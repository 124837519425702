import HelperClassesContainer from "../../Component/Ui-Kits/HelperClasses/HelperClasses"


const HelperClasses = () => {
  return (
    <div className='page-body'>
      <HelperClassesContainer />
    </div>
  )
}

export default HelperClasses