import { Input, Label, Table } from "reactstrap";
import { Btn, P, SVG } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { Href } from "../../../../utils/Constant";
import { todayTaskTabContentData } from "../../../../Data/Dashboard/Project/Project";
import { CommonTabContentTableType } from "../../../../Types/Dashborad/Project";

const CommonTabContentTable = ({value}:CommonTabContentTableType) => {
  return (
    <Table responsive className="display" style={{ width: "100%" }}>
      <tbody>
        {todayTaskTabContentData.slice(0,value).map((data, i) => (
          <tr key={i}>
            <td>
              <div className="form-check">
                <Input type="checkbox" />
                <Label />
              </div>
            </td>
            <td>
              <P>{data.detail}</P>
            </td>
            <td>
              <Btn color={data.color}>{data.status}</Btn>
            </td>
            <td>{data.date}</td>
            <td>
              <span className="delete-option">
                <Link to={Href}>
                  <SVG className="remove" iconId="Delete" />
                </Link>
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CommonTabContentTable;
