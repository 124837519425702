import { Container, Row } from "reactstrap";
import TotalSaleWidgets from "./TotalSaleWidgets/TotalSaleWidgets";
import MonthlyHistory from "./MonthlyHistory/MonthlyHistory";
import LiveProducts from "./LiveProducts/LiveProducts";
import StockMarket from "./StockMarket/StockMarket";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Chart, Widgets } from "../../../utils/Constant";
import ProjectCreated from "./ProjectCreated/ProjectCreated";
import SalesOverview from "./SalesOverview/SalesOverview";

const ContainerChart = () => {
  return (
    <>
      <Breadcrumbs mainTitle={Chart} parent={Widgets} />
      <Container fluid>
        <Row>
          <TotalSaleWidgets />
        </Row>
        <Row>
          <ProjectCreated />
          <SalesOverview />
          <MonthlyHistory />
        </Row>
        <Row>
          <LiveProducts />
        </Row>
        <StockMarket />
      </Container>
    </>
  );
};

export default ContainerChart;
