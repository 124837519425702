import ContainerContact from "../../../Component/Campaigns/Contact/Contact";

const Contact = () => {
  return (
    <div className="page-body">
      <ContainerContact />
    </div>
  );
};

export default Contact;
