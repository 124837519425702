import ContainerBlackList from "../../../Component/Contacts/BlackList/BlackList";

const BlackList = () => {
  return (
    <div className="page-body">
      <ContainerBlackList />
    </div>
  );
};

export default BlackList;
