import { Nav, NavItem, NavLink } from "reactstrap";
import { All, Href, Important, Links, Notes } from "../../../../utils/Constant";
import { TodayTaskTabType } from "../../../../Types/Dashborad/Project";

const TodayTaskTab = ({setTabId,tabId}:TodayTaskTabType) => {
  return (
    <Nav tabs className="border-tab mb-0" id="bottom-tab">
      <NavItem>
        <NavLink active={tabId === "1" ? true : false} className="nav-border tab-primary pt-0" href={Href} onClick={()=>setTabId("1")}>
          {All}<span className="ms-2 bg-primary">5</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink active={tabId === "2" ? true : false} className="nav-border tab-primary" href={Href} onClick={()=>setTabId("2")}>
          {Important}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink active={tabId === "3" ? true : false} className="nav-border tab-primary" href={Href} onClick={()=>setTabId("3")}>
          {Notes}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink active={tabId === "4" ? true : false} className="nav-border tab-primary" href={Href} onClick={()=>setTabId("4")}>
          {Links}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default TodayTaskTab;
