import ContainerCompaniesAdd from "../../../Component/Configurations/Companies/CompaniesAdd";

const CompaniesAdd = () => {
  return (
    <div className="page-body">
      <ContainerCompaniesAdd />
    </div>
  );
};

export default CompaniesAdd;
