import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const viewContactBlackList = async (
  id_contact: any,
  id_list: any,
  companyId: any
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/blacklist-contacts/${id_contact}/${id_list}/${companyId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching contact", error);
    throw error;
  }
};

const listContactsBlackList = async (
  companyId: any,
  id_contact_list: any
): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/blacklist-contacts/${id_contact_list}/${companyId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching contacts", error);
    throw error;
  }
};

const createContactBlackList = async (companyId: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/blacklist-contacts/${companyId}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating contact", error);
    throw error;
  }
};

const createContactBlackListByFile = async (companyIdentifier: string, data: any) => {
  try {
    console.log("data", data);

    const formData = new FormData();
    formData.append("file", data.files);
    formData.append("blacklist_identifier", data.contact_list_identifier);

    const response = await axios.post(
      `${API_URL}/blacklist-contacts/import-by-file/${companyIdentifier}`,
      formData,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating contact by file", error);
    throw error;
  }
};

const updateContactBlackList = async (
  data: any,
  identifier: any,
  companyIdentifier: any
): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/blacklist-contacts/${identifier}/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating contact", error);
    throw error;
  }
};

const deleteContactBlackList = async (
  id_contact: any,
  id_contact_list: any,
  id: any
): Promise<boolean> => {
  try {
    const response = await axios.delete(
      `${API_URL}/blacklist-contacts/${id_contact}/${id_contact_list}/${id}`,
      getAuthHeaders()
    );
    return response.status === 204;
  } catch (error) {
    console.error("Error deleting contact", error);
    throw error;
  }
};

export {
  viewContactBlackList,
  listContactsBlackList,
  createContactBlackList,
  updateContactBlackList,
  deleteContactBlackList,
  createContactBlackListByFile,
};
