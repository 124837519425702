import ReactApexChart from "react-apexcharts";
import { Col } from "reactstrap";
import { dailyVisitorsChartData } from "../../../../Data/Dashboard/DashboardChart/DashboardChart";
import { CommonDailyVisitorsChartType } from "../../../../Types/Dashborad/EcommerceDashboard";

const CommonDailyVisitorsChart = ({height}:CommonDailyVisitorsChartType) => {
  return (
    <Col xl="8" md="8" className="xl-12 box-col-12">
      <div className="area-line">
        <ReactApexChart
          options={dailyVisitorsChartData}
          series={dailyVisitorsChartData.series}
          height={height}
          type="area"
        />
      </div>
    </Col>
  );
};

export default CommonDailyVisitorsChart;
