import { Card, CardBody, Col, Row } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { DailyVisitorsHeading, Monthly, Weekly, Yearly } from "../../../../utils/Constant";
import DailyVisitorsChart from "./DailyVisitorsChart";
import DailyVisitorsOrderDetails from "./DailyVisitorsOrderDetails";

const DailyVisitors = () => {
  return (
    <Col xxl="6" lg="5" className="box-col-5">
      <Card className="visitors">
        <CommonCardHeader headClass="pb-0" title={DailyVisitorsHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody className="pb-0">
          <Row>
            <DailyVisitorsChart />
            <DailyVisitorsOrderDetails />
          </Row>
        </CardBody>
      </Card> 
    </Col>
  );
};

export default DailyVisitors;
