import { Card, Col, Row } from 'reactstrap'
import CommonProfileHead from '../Common/CommonProfileHead'
import { Link } from 'react-router-dom'
import { Image, P } from '../../../../../AbstractElements'
import { dynamicImage } from '../../../../../Service'
import { Href } from '../../../../../utils/Constant'
import CommonProfileLike from '../Common/CommonProfileLike'

const DetailImage = () => {
  return (
    <Col sm="12">
      <Card>
        <div className="profile-img-style">
          <CommonProfileHead  activeTime="10 Hours ago" image='7'/>
          <hr />
          <Row>
            <Col lg="12" xl="4"> 
              <div className="step8 mb-4">
                <Link to={Href}>
                  <Image className="img-fluid rounded" src={dynamicImage("blog/img.png")} alt="nature" />
                </Link>
              </div>
              <CommonProfileLike likeNumber={1989} commentsNumber={569} />
            </Col>
            <Col xl="6">
              <P>{"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consecteturContrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur"}</P>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  )
}

export default DetailImage