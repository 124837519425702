import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, Image, P } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { Href } from "../../utils/Constant";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { forgotPassword, resetPassword } from "../../Service/auth";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { t } = useTranslation();

  const cancelPassword = () => {
    setEmail("");
    setCode("");
    setPassword("");
    setConfirmPassword("");
    setIsForgotPassword(true);
  };

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    try {
      setLoading1(true);
      await forgotPassword({ email });
      toast.success(t("login1"));
      setIsForgotPassword(false);
      setLoading1(false);
    } catch (error) {
      console.error("Error during forgot password", error);
      toast.error(t("login1_error"));
    }
  };

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    try {
      setLoading2(true);
      await resetPassword({ email, code, password, confirmPassword });
      toast.success(t("login2"));
      setLoading2(false);
    } catch (error) {
      toast.error(t("login2_error"));
      console.error("Error during reset password", error);
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo-1.png")}
                    alt="login page"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo.png")}
                    alt="login page"
                  />
                </Link>
              </div>
              <div className="login-main">
                <Form
                  className="theme-form"
                  onSubmit={
                    isForgotPassword
                      ? handleForgotPassword
                      : handleResetPassword
                  }
                >
                  <H3>
                    {t(isForgotPassword ? "forgot_password" : "reset_password")}
                  </H3>
                  {isForgotPassword ? (
                    <>
                      <P>{t("write_email")}</P>
                      <FormGroup>
                        <Label className="col-form-label">{t("email")}</Label>
                        <Input
                          type="email"
                          required
                          placeholder="Test@gmail.com"
                          value={email}
                          name="email"
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 form-sub-title">
                        <div className="text-end mt-3">
                          <Btn
                            color="primary"
                            block
                            className="w-100"
                            type="submit"
                            disabled={loading1}
                          >
                            {loading1 ? t("loading") : t("submit")}
                          </Btn>
                        </div>
                      </FormGroup>
                    </>
                  ) : (
                    <>
                      <FormGroup>
                        <Label className="col-form-label">{t("email")}</Label>
                        <Input
                          type="email"
                          required
                          value={email}
                          name="email"
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{t("code")}</Label>
                        <Input
                          type="text"
                          required
                          placeholder="Enter your code"
                          value={code}
                          name="code"
                          onChange={(event) => setCode(event.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("password")}
                        </Label>
                        <Input
                          type="password"
                          required
                          placeholder="Enter your new password"
                          value={password}
                          name="password"
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("confirm_password")}
                        </Label>
                        <Input
                          type="password"
                          required
                          placeholder="Confirm your new password"
                          value={confirmPassword}
                          name="confirmPassword"
                          onChange={(event) =>
                            setConfirmPassword(event.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 form-sub-title">
                        <div
                          className="text-end mt-3 d-flex"
                          style={{ gap: "6px" }}
                        >
                          <Btn
                            style={{
                              backgroundColor: "#f0f0f0",
                              color: "#000",
                            }}
                            block
                            className="w-100"
                            onClick={() => {
                              cancelPassword();
                            }}
                          >
                            {t("cancel")}
                          </Btn>
                          <Btn
                            color="primary"
                            block
                            className="w-100"
                            type="submit"
                            disabled={loading2}
                          >
                            {loading2 ? t("loading") : t("reset_password")}
                          </Btn>
                        </div>
                      </FormGroup>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgetPassword;
