import ContainerCompanies from "../../../Component/Configurations/Companies/Companies";

const Companies = () => {
  return (
    <div className="page-body">
      <ContainerCompanies />
    </div>
  );
};

export default Companies;
