import { Modal, ModalBody } from "reactstrap";
import { CommonModalType } from "../../../Types/Ui-Kits/UiKitsTypes";
import { Btn, H1, H4, H5 } from "../../../AbstractElements";

const CommonModal: React.FC<CommonModalType> = ({
  backdrop,
  centered,
  size,
  isOpen,
  toggle,
  title,
  onClosed,
  sizeTitle,
  fullTitle,
  modalBodyClassName,
  children,
}) => {
  return (
    <Modal
      backdrop={backdrop}
      centered={centered}
      size={size}
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
    >
      {(title || sizeTitle || fullTitle) && (
        <div className="modal-header" onClick={toggle}>
          {title && <H5 className="modal-title">{title}</H5>}
          {sizeTitle && <H4>{sizeTitle}</H4>}
          {fullTitle && <H1 className="fs-5">{fullTitle}</H1>}
          <Btn className="py-0" close></Btn>
        </div>
      )}
      <ModalBody className={modalBodyClassName ? modalBodyClassName : ""}>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default CommonModal;
