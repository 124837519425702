import ContainerUsers from "../../../Component/Configurations/Users/Users";

const Users = () => {
  return (
    <div className="page-body">
      <ContainerUsers />
    </div>
  );
};

export default Users;
