import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  setSubmitError,
  loading,
  isEdit,
}) => {
  const { t } = useTranslation();

  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("name_label")}</Label>
            <Field
              name="name"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("name_placeholder")}
              {...(isEdit && { disabled: true })}
            />
            <ErrorMessage
              name="name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("cnpj_label")}</Label>
            <Field name="document">
              {({ field }: any) => (
                <InputMask
                  {...field}
                  mask="99.999.999/9999-99"
                  maskChar={null}
                  className={`form-control ${
                    submitErrors &&
                    `${errors.document ? "is-invalid" : "is-valid"}`
                  }`}
                  placeholder={t("cnpj_placeholder")}
                  {...(isEdit && { disabled: true })}
                />
              )}
            </Field>
            <ErrorMessage
              name="document"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("corporate_reason_label")}</Label>
            <Field
              name="social_name"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.social_name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("corporate_reason_placeholder")}
              {...(isEdit && { disabled: true })}
            />
            <ErrorMessage
              name="social_name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("additional_document_label")}</Label>
            <Field name="additional_document">
              {({ field }: any) => (
                <InputMask
                  {...field}
                  mask="999.999.999-99"
                  maskChar={null}
                  className={`form-control ${
                    submitErrors &&
                    `${errors.additional_document ? "is-invalid" : "is-valid"}`
                  }`}
                  placeholder={t("additional_document_placeholder")}
                  {...(isEdit && { disabled: true })}
                />
              )}
            </Field>
            <ErrorMessage
              name="additional_document"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("type_label")}</Label>
            <Field
              as="select"
              name="type"
              className={`form-control ${
                submitErrors && `${errors.type ? "is-invalid" : "is-valid"}`
              }`}
              {...(isEdit && { disabled: true })}
            >
              <option value="" />
              <option value="reseller" label={t("reseller")} />
              <option value="company" label={t("company")} />
              <option value="branch" label={t("branch")} />
            </Field>
            <ErrorMessage
              name="type"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="6">
          <FormGroup>
            <Label>{t("financial_phone_label")}</Label>
            <Field
              name="financial_phone"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.financial_phone ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("financial_phone_placeholder")}
              {...(isEdit && { disabled: true })}
            />
            <ErrorMessage
              name="financial_phone"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("financial_email_label")}</Label>
            <Field
              name="financial_email"
              type="email"
              className={`form-control ${
                submitErrors &&
                `${errors.financial_email ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("financial_email_placeholder")}
            />
            <ErrorMessage
              name="financial_email"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup className="check-company">
            <Field
              id="activeCheck"
              name="active"
              className={`form-check-input ${
                submitErrors && `${errors.active ? "is-invalid" : "is-valid"}`
              }`}
              type="checkbox"
            />
            <Label for="activeCheck" className="m-l-10">
              {t("active_label")}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Btn
        color="primary"
        type="submit"
        onClick={() => setSubmitError(true)}
        disabled={loading}
      >
        {loading ? t("loading") : t("submit")}
      </Btn>
    </Form>
  );
};

export default FormValidations;
