import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  token: string | null;
  userInfo: object | null | any;
  isLoggedIn: boolean | null | any;
}

const initialState: UserState = {
  token: null,
  userInfo: null,
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (
      state,
      action: PayloadAction<{
        token: string;
        userInfo: object;
        isLoggedIn: boolean;
      }>
    ) => {
      state.token = action.payload.token;
      state.userInfo = action.payload.userInfo;
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    clearUserData: (state) => {
      state.token = null;
      state.userInfo = null;
      state.isLoggedIn = false;
      localStorage.removeItem("login");
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;
