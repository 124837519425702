import { Fragment, useState } from "react";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import { MenuList, MenuListCustomers, MenuListReseller } from "../../Data/LayoutData/SidebarData";
import Menulist from "./Menulist";
import { MenuItem } from "../../Types/Layout/SidebarType";
import { H6, LI } from "../../AbstractElements";
import { useTranslation } from "react-i18next";
import { CompanyTypeEnum } from "../../Enums/company.type.enum";

const SidebarMenuList = () => {
  const [activeMenu, setActiveMenu] = useState<string[]>([]);
  const { pinedMenu } = useAppSelector((state) => state.layout);
  const { t } = useTranslation();

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const companyType = userData ? JSON.parse(userData)?.userInfo?.company_type : null;

  const shouldHideMenu = (mainMenu: MenuItem) => {
    return mainMenu?.Items?.map((data) => data.title).every((titles) =>
      pinedMenu.includes(titles || "")
    );
  };

  return (
    <>
      {companyType === CompanyTypeEnum.OWNER && MenuList && (
        MenuList.map((mainMenu: MenuItem, index) => (
          <Fragment key={index}>
            <LI
              className={`sidebar-main-title ${
                shouldHideMenu(mainMenu) ? "d-none" : ""
              }`}
            >
              <div>
                <H6 className={mainMenu.lanClass && mainMenu.lanClass}>
                  {t(mainMenu.title)}
                </H6>
              </div>
            </LI>
            <Menulist
              menu={mainMenu.Items}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              level={0}
            />
          </Fragment>
        ))
      )}

      {companyType === CompanyTypeEnum.RESELLER && MenuListReseller && (
        MenuListReseller.map((mainMenu: MenuItem, index) => (
          <Fragment key={index}>
            <LI
              className={`sidebar-main-title ${
                shouldHideMenu(mainMenu) ? "d-none" : ""
              }`}
            >
              <div>
                <H6 className={mainMenu.lanClass && mainMenu.lanClass}>
                  {t(mainMenu.title)}
                </H6>
              </div>
            </LI>
            <Menulist
              menu={mainMenu.Items}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              level={0}
            />
          </Fragment>
        ))
      )}

      {(companyType !== CompanyTypeEnum.OWNER && companyType !== CompanyTypeEnum.RESELLER) && MenuListCustomers && (
        MenuListCustomers.map((mainMenu: MenuItem, index) => (
          <Fragment key={index}>
            <LI
              className={`sidebar-main-title ${
                shouldHideMenu(mainMenu) ? "d-none" : ""
              }`}
            >
              <div>
                <H6 className={mainMenu.lanClass && mainMenu.lanClass}>
                  {t(mainMenu.title)}
                </H6>
              </div>
            </LI>
            <Menulist
              menu={mainMenu.Items}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              level={0}
            />
          </Fragment>
        ))
      )}
    </>
  );
};

export default SidebarMenuList;
