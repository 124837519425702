import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  const isLoggedIn = userData?.isLoggedIn;

  return isLoggedIn !== false ? (
    <Outlet />
  ) : (
    <Navigate to={`${process.env.PUBLIC_URL}/login`} />
  );
};

export default PrivateRoute;
