import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import { deleteTransaction } from "../../../Service/transaction";
import { format } from "date-fns";
import { listBalance } from "../../../Service/balance";

const ContainerBalance = () => {
  const { t } = useTranslation();

  const mainTitle = "balance";
  const base = `${process.env.PUBLIC_URL}/financial/balance`;
  const textBase = [{ text: t("balance_text") }];

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const [data, setData] = useState<any>();

  const fetchData = useCallback(async () => {
    try {
      const response = await listBalance(identifier);
      setData(response.balances);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [identifier]);

  const ExcludeConfirm = (id: string | undefined, transactionCompanyIdentifier: string | undefined) => {
    SweetAlert.fire({
      title: t("alert_title"),
      text: t("alert_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("alert_cancel"),
      confirmButtonText: t("alert_button"),
    }).then(async (result) => {
      if (result.isConfirmed) {
      }
      if (result.isConfirmed) {
        await deleteTransaction(id, transactionCompanyIdentifier)
          .then(() => {
            fetchData();
            SweetAlert.fire({
              title: t("alert_confirm_title"),
              text: t("alert_confirm_text"),
              icon: "success",
            });
          })
          .catch(() => {
            SweetAlert.fire({
              title: t("alert_confirm_title_error"),
              text: t("alert_confirm_text_error"),
              icon: "error",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: t("balance"),
      selector: (row: any) => `${row.balance.toFixed(2) ?? "0.00"}`,
      sortable: true,
    },
    {
      name: t("inputs"),
      selector: (row: any) => `${row.inputs.toFixed(2) ?? "0.00"}`,
      sortable: true,
    },
    {
      name: t("outputs"),
      selector: (row: any) => `${row?.outputs?.toFixed(2) ?? "0.00"}`,
      sortable: true,
    },
    {
      name: t("company_identifier"),
      selector: (row: any) => `${row.company_identifier}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };
  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <CardHeaderCommon title={t("balance")} span={textBase} />
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerBalance;
