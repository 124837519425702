import ContainerPosPaid from "../../../Component/Financial/PosPaid/PosPaid";

const PosPaid = () => {
  return (
    <div className="page-body">
      <ContainerPosPaid />
    </div>
  );
};

export default PosPaid;
