import { Link } from "react-router-dom";
import { H6, LI, UL } from "../../../../AbstractElements";
import { Href } from "../../../../utils/Constant";
import { ClientBudgetPropsType } from "../../../../Types/Dashborad/Project";

const ClientBudget = ({ data }: ClientBudgetPropsType) => {
  return (
    <div className="client-budget">
      <UL className="simple-list">
        {data.clientDetails.map((item, i) => (
          <LI key={i}>
            <div className="d-flex">
              {item.color &&
                <div className="flex-shrink-0">
                  <div className={`activity-${item.color}`} />
                </div> 
              }
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between">
                  <Link to={Href}>
                    <H6>{item.title}</H6>
                    <span>{item.date}</span>
                  </Link>
                </div>
              </div>
            </div>
          </LI>
        ))}
      </UL>
    </div>
  );
};

export default ClientBudget;
