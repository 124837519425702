import { Card, CardBody, Col } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, ProjectStatisticsHeading, Weekly, Yearly } from "../../../../utils/Constant";
import ReactApexChart from "react-apexcharts";
import { statisticsChartData } from "../../../../Data/Dashboard/DashboardChart/DashboardChart";

const ProjectStatistics = () => {
  return (
    <>
    <Col xl="6">
      <Card>
        <CommonCardHeader headClass="pb-0" title={ProjectStatisticsHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody className="p-0">
          <ReactApexChart options={statisticsChartData} series={statisticsChartData.series} height={252} type="bubble" id="Statistics" />
        </CardBody>
      </Card>
    </Col>
  </>
  );
};

export default ProjectStatistics;
