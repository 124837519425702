import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { General, Widgets } from "../../../utils/Constant";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import ProductSales from "./ProductSales/ProductSales";
import StockReport from "./StockReport/StockReport";
import TrackOrder from "./TrackOrder/TrackOrder";
import ProjectDeliveries from "./ProjectDeliveries/ProjectDeliveries";
import TotalRevenue from "./TotalRevenue/TotalRevenue";
import MobileApp from "./MobileApp/MobileApp";
import DailyVisitors from "./DailyVisitors/DailyVisitors";
import TotalEarnings from "./TotalEarnings/TotalEarnings";
import TotalExpenses from "./TotalExpenses/TotalExpenses";

const ContainerGeneral = () => {
  return (
    <>
      <Breadcrumbs mainTitle={General} parent={Widgets} />
      <Container fluid className="general-widget">
        <Row>
          <MobileApp />
          <DailyVisitors />
          <TotalEarnings />
          <TotalExpenses />
          <TransactionHistory />
          <ProductSales />
          <TotalRevenue />
          <StockReport />
          <TrackOrder />
          <ProjectDeliveries />
        </Row>
      </Container>
    </>
  );
};

export default ContainerGeneral;
