import { Col } from "reactstrap";
import { H4 } from "../../../../AbstractElements";
import { dailyVisitorsOrderData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";
import OrderProgress from "./OrderProgress";

const DailyVisitorsOrderDetails = () => {
  return (
    <Col md="4" className="ecommerce-chart daily-visitors">
      <div className="d-flex">
        {dailyVisitorsOrderData.map((data, i) => (
          <div className={`flex-shrink-0 bg-light-${data.color} ${data.class}`} key={i}>
            <div className="deals">
              <H4>{data.count}</H4>
              <span>{data.title}</span>
            </div>
          </div>
        ))}
      </div>
      <OrderProgress />
    </Col>
  );
};

export default DailyVisitorsOrderDetails;
