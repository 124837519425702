import ContainerBlacklistContacts from "../../../Component/Contacts/BlacklistContacts/BlacklistContacts";

const BlacklistContacts = () => {
  return (
    <div className="page-body">
      <ContainerBlacklistContacts />
    </div>
  );
};

export default BlacklistContacts;
