import ContainerGatewayAdd from "../../../Component/Configurations/Gateway/GatewayAdd";

const Gateway = () => {
  return (
    <div className="page-body">
      <ContainerGatewayAdd />
    </div>
  );
};

export default Gateway;
