import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  setSubmitError,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="12">
          <FormGroup>
            <Label>{t("number_label")}</Label>
            <Field name="number">
              {({ field }: any) => (
                <InputMask
                  {...field}
                  mask="99 (99) 99999-9999"
                  maskChar={null}
                  className={`form-control ${
                    submitErrors &&
                    `${errors.number ? "is-invalid" : "is-valid"}`
                  }`}
                  placeholder={t("phone_placeholder")}
                />
              )}
            </Field>
            <ErrorMessage
              name="number"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("date_in")}</Label>
            <Field
              name="date_in"
              type="date"
              className={`form-control ${
                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("date_in")}
            />
            <ErrorMessage
              name="date_in"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("date_out")}</Label>
            <Field
              name="date_out"
              type="date"
              className={`form-control ${
                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("date_out")}
            />
            <ErrorMessage
              name="date_in"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={loading}
          >
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
