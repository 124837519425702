import ContainerSMSReports from "../../../Component/Campaigns/Sms/SmsReports";
import ContainerSMSReportsDetails from "../../../Component/Campaigns/Sms/SmsReportsDetails";

const SMSReportsDetails = () => {
  return (
    <div className="page-body">
      <ContainerSMSReportsDetails />
    </div>
  );
};

export default SMSReportsDetails;
