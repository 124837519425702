import ContainerProcon from "../../../Component/Contacts/Procon/Procon";

const Procon = () => {
  return (
    <div className="page-body">
      <ContainerProcon />
    </div>
  );
};

export default Procon;
