import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import CommonModal from "./CommonModalContactList";
import * as Yup from "yup";
import { Formik } from "formik";
import FormValidations from "./ValidationsForm";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  listBlackList,
  createBlackList,
  deleteBlackList,
  viewBlackList,
  updateBlackList,
} from "../../../Service/blackList";
import { toast } from "react-toastify";
import { format } from "date-fns";

const ContainerBlackList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const mainTitle = "blacklist";
  const base = `${process.env.PUBLIC_URL}/contact/blacklist`;

  const [edit, setEdit] = useState(false);
  const [centred, setCentered] = useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [idEdit, setIdEdit] = useState<any>();

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const fetchData = useCallback(async () => {
    try {
      const response = await listBlackList(identifier);
      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [identifier]);

  const centeredToggle = () => setCentered(!centred);

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const ExcludeConfirm = () => {
      SweetAlert.fire({
        title: t("alert_title"),
        text: t("alert_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("alert_cancel"),
        confirmButtonText: t("alert_button"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteBlackList(row.row.identifier, identifier)
            .then(() => {
              fetchData();
              SweetAlert.fire({
                title: t("alert_confirm_title"),
                text: t("alert_confirm_text_contact_list"),
                icon: "success",
              });
            })
            .catch(() => {
              SweetAlert.fire({
                title: t("alert_confirm_title_error"),
                text: t("alert_confirm_text_contact_list_error"),
                icon: "error",
              });
            });
        }
      });
    };

    const handleItemClick = (action: any) => {
      if (action === "delete") {
        ExcludeConfirm();
        setDropdownOpen(false);
      } else if (action === "edit") {
        setEdit(true);
        setIdEdit(row.row);
        setLoading(true);
        viewBlackList(row.row.identifier, identifier).then((e: any) => {
          setValidationFormInitialValue(e);
        });
        setLoading(false);
        centeredToggle();
        setDropdownOpen(false);
      } else if (action === "access") {
        navigate(
          `${process.env.PUBLIC_URL}/contact/backlist/contacts/${row.row.identifier}/${row.row.name}`
        );
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("edit")}>
            {t("edit")}
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick("delete")}>
            {t("delete")}
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick("access")}>
            {t("access")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const columns = [
    {
      name: t("name"),
      selector: (row: any) => `${row.name}`,
      sortable: true,
    },
    {
      name: t("quantity"),
      selector: (row: any) => `${row.number_of_contacts}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const blackListData = {
        name: values.name,
      };
      if (edit) {
        await updateBlackList(blackListData, idEdit.identifier, identifier);
      } else {
        await createBlackList(identifier, blackListData);
      }
      resetForm();
      fetchData();
      centeredToggle();
      toast.success(t("success_black_list"));
      setSubmitError(false);
    } catch (error) {
      console.error("Error saving _black_list", error);
      setSubmitError(true);
      toast.error(t("error_black_list"));
    } finally {
      setLoading(false);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      name: "",
      company_identifier: identifier,
    });

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required(t("name_alert")),
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("contacts")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <div style={{ visibility: "hidden", marginTop: "20px" }}>
                  <CardHeaderCommon title={t("blacklist")} />
                </div>
                <div className="btn-search-companies">
                  <Button
                    color="primary"
                    onClick={() => {
                      setEdit(false);
                      setValidationFormInitialValue({
                        name: "",
                        company_identifier: identifier,
                      });
                      centeredToggle();
                    }}
                  >
                    {t("add_blacklist")}
                  </Button>
                </div>
                <CommonModal
                  size="lg"
                  backdrop="static"
                  centered
                  title={
                    edit ? t("edit_blacklist_title") : t("add_blacklist_title")
                  }
                  isOpen={centred}
                  toggle={centeredToggle}
                >
                  <div className="modal-toggle-wrapper">
                    <Formik
                      initialValues={validationFormInitialValue}
                      onSubmit={handleSubmit}
                      validationSchema={formValidationSchema}
                      enableReinitialize
                    >
                      {({ errors }) => (
                        <FormValidations
                          submitErrors={submitErrors}
                          setSubmitError={setSubmitError}
                          errors={errors}
                          loading={loading}
                        />
                      )}
                    </Formik>
                  </div>
                </CommonModal>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    onRowClicked={(row) =>
                      navigate(
                        `${process.env.PUBLIC_URL}/contact/backlist/contacts/${row.identifier}/${row.name}`
                      )
                    }
                    customStyles={customStyles}
                    pagination
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerBlackList;
