import { Col } from 'reactstrap'
import { H3 } from '../../../../AbstractElements'
import { TextColors } from '../../../../utils/Constant'
import { textColorData } from '../../../../Data/Ui-Kits/HelperClasses/HelperClassesData'

const TextColor = () => {
  return (
    <Col xxl="3" sm="6">
      <div className="border-wrapper alert-light-light h-100 dark-helper">
        <H3 className="mb-3">{TextColors}</H3>
        <div className="helper-common-box">
          <div className="helper-box helper-text border txt-primary">C </div>
          <span>.txt-primary</span>
        </div>
        {textColorData.map((item, index) => (
          <div className="helper-common-box" key={index}>
            <div className={`helper-box helper-text border txt-${item} ${item === "light" ? "bg-dark" : ""}`}>C </div>
            <span>.txt-{item}</span>
          </div>
        ))}
      </div>
    </Col>
  )
}

export default TextColor