import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import FormValidations from "./ValidationsForm";
import { createCredit, listCompanyToSelect } from "../../../Service/credit";
import { viewTransaction } from "../../../Service/transaction";

const ContainerCreditsAdd = () => {
  const { t } = useTranslation();
  const mainTitle = "add_credit_text";
  const base = `${process.env.PUBLIC_URL}/financial/credits`;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState<any[]>([]);
  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const fetchCompanySelect = async () => {
    const responseSelect = await listCompanyToSelect(identifier);

    const companiesList = responseSelect.map((company: any) => ({
      value: company.identifier,
      label: `${company.document} - ${company.name}`,
    }));

    setCompanyData(companiesList);
  };
  
  const fetchCompany = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response: any = await viewTransaction(identifier, id);
        setValidationFormInitialValue(response);
      } catch (error) {
        console.error("Erro ao buscar Company:", error);
        toast.error(t("error_transaction"));
      } finally {
        setLoading(false);
      }
    },
    [t]
  );

  useEffect(() => {
    if (id) {
      fetchCompany(id);
    }
  }, [fetchCompany, id]);

  useEffect(() => {
    fetchCompanySelect();
  }, []);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const transactionData = {
        amount: values.amount, 
        type: values.type,
        is_prepaid: true,
        subtype: 'credit_transfer',
        description: values.description,
        company_identifier: values.company_identifier,
      };

      await createCredit(transactionData, identifier)
        .then(() => {
          toast.success(t("success_transaction"));
          navigate(`${process.env.PUBLIC_URL}/financial/credits`);
          resetForm();
        })
        .catch((error) => {
          console.log("Error creating transaction", error);
          const message  = error?.response?.data.message ?? "error_transaction";
          toast.error(t(message));
        });

      setSubmitError(false);
    } catch (error) {
      console.error("Error creating company", error);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] = useState({
    amount: "", 
    type: "",
    description: "",
    company_identifier: "",
  });

  const formValidationSchema = Yup.object().shape({
    amount: Yup.string().required(t("amount_alert")),
    type: Yup.string().required(t("type_alert")),
    description: Yup.string().required(t("description_alert")),
    company_identifier: Yup.string().required(t("company_alert")),
  });

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Transactions")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={validationFormInitialValue}
                  onSubmit={handleSubmit}
                  validationSchema={formValidationSchema}
                  enableReinitialize
                >
                  {({ errors }) => (
                    <FormValidations
                      submitErrors={submitErrors}
                      setSubmitError={setSubmitError}
                      errors={errors}
                      loading={loading}
                      dataSelect={companyData}
                    />
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerCreditsAdd;
