import { P, Progressbar } from "../../../../AbstractElements";
import { orderProgressData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";

const OrderProgress = () => {
  return (
    <>
      {orderProgressData.map((data, i) => (
        <div className="progress-value" key={i}>
          <P>
            {data.title}<span>{data.count}</span>
          </P>
          <Progressbar value={data.value} color={data.color} />
        </div>
      ))}
    </>
  );
};

export default OrderProgress;
