import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  setSubmitError,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("name_label_campaign")}</Label>
            <Field
              name="name"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("name_placeholder_campaign")}
            />
            <ErrorMessage
              name="name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>

        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={loading}
          >
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
