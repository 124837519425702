import { Card, CardBody, Col } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, TodayTaskHeading, Weekly, Yearly } from "../../../../utils/Constant";
import TodayTaskTab from "./TodayTaskTab";
import TodayTaskTabContent from "./TodayTaskTabContent";
import { useState } from "react";

const TodayTask = () => {
  const [tabId,setTabId] = useState<string>("1")
  return (
    <Col xl="5" lg="7" md="7">
      <Card className="today-task">
        <CommonCardHeader headClass="pb-0" title={TodayTaskHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody>
          <div className="d-flex header-tab">
            <TodayTaskTab setTabId={setTabId} tabId={tabId}/>
          </div>
          <TodayTaskTabContent tabId={tabId} setTabId={setTabId} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TodayTask;
