import { Link } from "react-router-dom";
import { Badges, H6, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { topProductBodyData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";

const TopProductBody = () => {
  return (
    <div className="d-flex">
      {topProductBodyData.map((data, i) => (
        <div className="card-product" key={i}>
          <Link to={`${process.env.PUBLIC_URL}/ecommerce/products`}>
            <span>
              <Image src={dynamicImage(`dashboard-2/product/${data.image}`)} alt="product"/>
            </span>
            {data.status && <Badges color={data.color}>{data.status}</Badges>}
            <div className="details-product">
              <H6>{data.productName}</H6>
              <div className="price-product">
                {data.amount}
                <del>{data.mainAmount}</del>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default TopProductBody;
