export let languageData = [
  {
    languageParameter: "pt",
    languageName: "Português",
    languageIconClassName: "br",
    subTitle: "BR",
  },
  {
    languageParameter: "en",
    languageName: "English",
    languageIconClassName: "us",
    subTitle: "US",
  },
  {
    languageParameter: "cn",
    languageName: "简体中文",
    languageIconClassName: "cn",
  },
];
