import { Col, Row } from "reactstrap";
import CommonTotalRevenueCard from "./CommonTotalRevenueCard";
import { totalRevenueData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";
import { Fragment } from "react";

const TotalRevenue = () => {
  return (
    <Col xxl="6" lg="7" className="box-col-7">
      <Row>
        {totalRevenueData.map((data,i)=>(
          <Fragment key={i}>
            <CommonTotalRevenueCard icon={data.icon} amount={data.amount} title={data.title} arrowIcon={data.arrowIcon} percentage={data.percentage}  chart={data.chart} classExtra={data.classExtra} chartClass={data.chartClass}/>
          </Fragment>
        ))}
      </Row>
    </Col>
  );
};

export default TotalRevenue;
