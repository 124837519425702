import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Dashboard, Project } from "../../../utils/Constant";
import WebsiteDesign from "./WebsiteDesign/WebsiteDesign";
import TotalRevenue from "./TotalRevenue/TotalRevenue";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";
import TodayTask from "./TodayTask/TodayTask";
import ProjectSummery from "./ProjectSummery/ProjectSummery";
import ProjectStatistics from "./ProjectStatistics/ProjectStatistics";
import MobileApp from "./MobileApp/MobileApp";
import UpcomingDeadlines from "./UpcomingDeadlines/UpcomingDeadlines";
import ProjectDeliveries from "./ProjectDeliveries/ProjectDeliveries";
import TeamMembers from "./TeamMembers/TeamMembers";

const ContainerProject = () => {
  return (
    <>
      <Breadcrumbs mainTitle={Project} parent={Dashboard} />
      <Container fluid className="project-dashboard">
        <Row>
          <WebsiteDesign />
          <TotalRevenue />
          <SubscriptionPlan />
          <TodayTask />
          <ProjectSummery />
          <ProjectStatistics />
          <MobileApp />
          <UpcomingDeadlines />
          <ProjectDeliveries />
          <TeamMembers />
        </Row>
      </Container>
    </>
  );
};

export default ContainerProject;
