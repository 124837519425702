import ReactApexChart from 'react-apexcharts'
import { reportChartData } from '../../../../Data/Dashboard/DashboardChart/DashboardChart'
import { CommonDailyVisitorsChartType } from '../../../../Types/Dashborad/EcommerceDashboard'

const CommonStockReportChart = ({height}:CommonDailyVisitorsChartType) => {
  return (
    <ReactApexChart options={reportChartData} series={reportChartData.series} height={height} type="bar"/>
  )
}

export default CommonStockReportChart