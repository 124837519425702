import { Col } from 'reactstrap'
import { ExtendedBackgroundColor } from '../../../../utils/Constant'
import { H3 } from '../../../../AbstractElements'

const ExtendedBackgroundColors = () => {
  return (
    <Col xl="4" sm="12">
      <div className="border-wrapper h-100 border">
        <H3 className="mb-3">{ExtendedBackgroundColor}</H3>
        <div className="helper-common-box">
          <div className="helper-box light-card"> </div>.light-card
        </div>
        <div className="helper-common-box">
          <div className="helper-box light-background border"> </div>.light-background
        </div>
      </div>
    </Col>
  )
}

export default ExtendedBackgroundColors