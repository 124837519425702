import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Badges } from "../../../AbstractElements";
import { PosPaidStatusEnum } from "../../../Enums/pos-paid.status.enum";
import MonthYearPicker from "../../../CommonElements/MonthYearPicker/MonthYearPicker";
import { listPosPaid } from "../../../Service/pos-paid";

const ContainerPosPaid = () => {
  const { t } = useTranslation();

  const mainTitle = "pos_paid";
  const base = `${process.env.PUBLIC_URL}/financial/pos-paid`;
  const textBase = [{ text: t("pos_paid_text") }];

  const userData = localStorage.getItem("M2C:userData");

  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const [period, setPeriod] = useState<any>();

  const [data, setData] = useState<any>();

  const StatusChip = ({ status }: any) => {
    switch (status) {
      case PosPaidStatusEnum.PAID:
        return (
          <Badges className="badge-light-success btn" color="transparant">
            {t("paid")}
          </Badges>
        );
      
      case PosPaidStatusEnum.OPEN:
        return (
          <Badges className="btn-danger btn" color="transparant">
            {t("open")}
          </Badges>
        );

      default:
        return (
          <Badges className="btn-danger btn" color="transparant">
            {t("open")}
          </Badges>
        );
    }
  };

  const handleOnChange = async (value: string) => {
    setPeriod(value);
    
    const data = await listPosPaid(identifier, value);

    if (data) {
      setData(data);
    }
  }

  const columns = [
    {
      name: t("period"),
      selector: (row: any) => `${row.period}`,
      sortable: true,
    },
    {
      name: t("status"),
      selector: (row: any) => row.type,
      cell: (row: any) => <StatusChip status={row.status} />,
      sortable: true,
    },
    {
      name: t("value"),
      selector: (row: any) => `${row.value}`,
      sortable: true,
    },
    {
      name: t("close_date"),
      selector: (row: any) => `${format(row.pos_paid_close_at, "yyyy-MM-dd")}`,
      sortable: true,
    },
    {
      name: t("delayed_payment_days"),
      selector: (row: any) => `${row.delayed_payment_days}`,
      sortable: true,
    },
    {
      name: t("company"),
      selector: (row: any) => `${row.company_name}`,
      sortable: true,
    },
    {
      name: t("document"),
      selector: (row: any) => `${row.company_document}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
    // {
    //   name: t("actions"),
    //   cell: (row: any) => <ColumnAction row={row} />,
    //   sortable: true,
    //   center: true,
    // },
  ];

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${year}-${month}`;

    setPeriod(formattedDate);

    handleOnChange(formattedDate);
  }, []);

  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <CardHeaderCommon title={t("list")} span={textBase} />
                <MonthYearPicker
                  label={t("select_period")} 
                  value={period}
                  onChange={(value) => handleOnChange(value)} 
                />
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerPosPaid;
