import { Col, Container, Row } from 'reactstrap'
import CommonButtons from '../CommonButtons/CommonButtons'
import { ActiveButtonsHeading, BoldBorderOutlineButtonsHeading, Buttons, DefaultButtonsHeading, DefaultStyles, DisabledButtonsHeading, FlatButton, GradientButtonsHeading, LargeButtonsHeading, OutlineButtonsHeading, RaisedButton } from '../../../utils/Constant'
import { activeButtonsData, activeButtonsHeadingData, boldBorderOutlineButtonsData, boldBorderOutlineButtonsHeadingData, defaultButtonsData, defaultButtonsHeadingData, disabledButtonsData, disabledButtonsHeadingData, flatButtonsData, flateButtonsHeadingData, gradientButtonsData, gradientButtonsHeadingData, largeButtonsData, largeButtonsHeadingData, outlineButtonsData, outlineButtonsHeadingData, raisedDefaultButtonsData, raisedDefaultButtonsHeadingData } from '../../../Data/Buttons/DefaultStyle'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'

const DefaultStyleContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={DefaultStyles} parent={Buttons} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <CommonButtons commonButtonsData={defaultButtonsData} title={DefaultButtonsHeading} subTitle={defaultButtonsHeadingData} />
            <CommonButtons className="btn-square" commonButtonsData={flatButtonsData} title={FlatButton} subTitle={flateButtonsHeadingData} />
            <CommonButtons raised className="btn-pill" commonButtonsData={raisedDefaultButtonsData} title={RaisedButton} subTitle={raisedDefaultButtonsHeadingData} />
            <CommonButtons commonButtonsData={largeButtonsData} title={LargeButtonsHeading} subTitle={largeButtonsHeadingData} />
            <CommonButtons commonButtonsData={activeButtonsData} title={ActiveButtonsHeading} subTitle={activeButtonsHeadingData} />
            <CommonButtons commonButtonsData={disabledButtonsData} title={DisabledButtonsHeading} subTitle={disabledButtonsHeadingData} className="text-white"/>
            <CommonButtons commonButtonsData={outlineButtonsData} title={OutlineButtonsHeading} subTitle={outlineButtonsHeadingData} />
            <CommonButtons commonButtonsData={boldBorderOutlineButtonsData} title={BoldBorderOutlineButtonsHeading} subTitle={boldBorderOutlineButtonsHeadingData}/>
            <CommonButtons commonButtonsData={gradientButtonsData} title={GradientButtonsHeading} subTitle={gradientButtonsHeadingData} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default DefaultStyleContainer