import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { listServicesForSelectByUser } from "../../../Service/service";
import { useNavigate } from "react-router-dom";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  loading,
  setSubmitError,
  userId,
}) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [services, setServices] = useState<any[]>([]);

  const userData = localStorage.getItem("M2C:userData");

  if (!userData) {
    navigate(`${process.env.PUBLIC_URL}`);
  }

  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const fetchServices = async () => {
    try {
      const response = await listServicesForSelectByUser(identifier, userId);
      setServices(response);
    } catch (error) {
      console.error("Error fetching services", error);
    }
  }

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("first_name_label_user")}</Label>
            <Field
              name="first_name"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.first_name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("first_name_placeholder_user")}
            />
            <ErrorMessage
              name="first_name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("last_name_label_user")}</Label>
            <Field
              name="last_name"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.last_name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("last_name_placeholder_user")}
            />
            <ErrorMessage
              name="last_name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("password_label_user")}</Label>
            <Field
              name="password"
              type={show ? "text" : "password"}
              className={`form-control ${
                submitErrors && `${errors.password ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("password_placeholder_user")}
            />
            <div className="show-hide" onClick={() => setShow(!show)}>
              <span className="show"> </span>
            </div>
            <ErrorMessage
              name="password"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("email_label_user")}</Label>
            <Field
              name="email"
              type="email"
              className={`form-control ${
                submitErrors && `${errors.email ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("email_placeholder_user")}
            />
            <ErrorMessage
              name="email"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("phone_label_user")}</Label>
            <Field
              name="mobile_phone"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.mobile_phone ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("phone_placeholder_user")}
            />
            <ErrorMessage
              name="mobile_phone"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("type_label_user")}</Label>
            <Field
              as="select"
              name="type"
              className={`form-control ${
                submitErrors && `${errors.type ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value=""></option>
              <option value="root">Root</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </Field>
            <ErrorMessage
              name="type"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="1">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("use_api")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="use_api" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="use_api"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup>
            <Label check>{t("service_to_api")}</Label>
            <Field
              as="select"
              name="service_identifier"
              className={`form-control ${
                submitErrors &&
                `${errors.service_identifier ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value={""}>{""}</option>
              {services &&
                services.map((item: any, index: number) => (
                  <option value={item.identifier} key={index}>
                    {item.name}
                  </option>
                ))}
            </Field>
            <ErrorMessage
              name="company_identifier"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="7"></Col>
        <Col xs="12" md="2">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("active")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="active" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="active"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={loading}
          >
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
