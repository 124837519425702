import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import FormValidations from "./ValidationsForm";
import {
  createCompany,
  updateCompany,
  viewCompany,
} from "../../../Service/company";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ContainerCompaniesAdd = () => {
  const { t } = useTranslation();
  const mainTitle = "add_company_text";
  const base = `${process.env.PUBLIC_URL}/configurations/companies`;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const fetchCompany = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response: any = await viewCompany(id);
        setValidationFormInitialValue(response);
      } catch (error) {
        console.error("Erro ao buscar Company:", error);
        toast.error(t("error_Company"));
      } finally {
        setLoading(false);
      }
    },
    [t]
  );

  useEffect(() => {
    if (id) {
      fetchCompany(id);
    }
  }, [fetchCompany, id]);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const companyData = {
        document: values.document,
        name: values.name,
        type: values.type,
        social_name: values.social_name,
        additional_document: values.additional_document,
        financial_phone: values.financial_phone,
        financial_email: values.financial_email,
        active: values.active,
      };
      if (id) {
        await updateCompany(id, companyData)
          .then(() => {
            toast.success(t("success_company"));
            navigate(`${process.env.PUBLIC_URL}/companies`);
          })
          .catch(() => {
            toast.error(t("error_company"));
          });
      } else {
        await createCompany(companyData, identifier)
          .then(() => {
            toast.success(t("success_company"));
            navigate(`${process.env.PUBLIC_URL}/companies`);
            resetForm();
          })
          .catch(() => {
            toast.error(t("error_company"));
          });
      }

      setSubmitError(false);
    } catch (error) {
      console.error("Error creating company", error);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] = useState({
    document: "",
    name: "",
    type: "",
    social_name: "",
    additional_document: "",
    financial_phone: "",
    financial_email: "",
  });

  const formValidationSchema = Yup.object().shape({
    document: Yup.string().required(t("cnpj_alert")),
    name: Yup.string().required(t("name_alert")),
    type: Yup.string().required(t("type_alert")),
    social_name: Yup.string().required(t("corporate_reason_alert")),
    additional_document: Yup.string().required(t("state_registration_alert")),
    financial_phone: Yup.string().required(t("financial_phone_alert")),
    financial_email: Yup.string().required(t("financial_email_alert")),
  });

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={validationFormInitialValue}
                  onSubmit={handleSubmit}
                  validationSchema={formValidationSchema}
                  enableReinitialize
                >
                  {({ errors }) => (
                    <FormValidations
                      submitErrors={submitErrors}
                      setSubmitError={setSubmitError}
                      errors={errors}
                      loading={loading}
                      isEdit={id ? true : false}
                    />
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerCompaniesAdd;
