import ContainerCredits from "../../../Component/Financial/Credits/Credits";

const Credits = () => {
  return (
    <div className="page-body">
      <ContainerCredits />
    </div>
  );
};

export default Credits;
