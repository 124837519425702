import { Col } from 'reactstrap'
import { BorderWidth } from '../../../../utils/Constant'
import { borderWidthData } from '../../../../Data/Ui-Kits/HelperClasses/HelperClassesData'
import { H3 } from '../../../../AbstractElements'

const BorderWidths = () => {
  return (
    <Col xxl="3" sm="6">
      <div className="border-wrapper h-100 alert-light-light dark-helper">
        <H3 className="mb-3">{BorderWidth}</H3>
        <div className="helper-common-box">
          <div className="helper-box border-1 border"> </div>
          <span>.border-1</span>
        </div>
        {borderWidthData.map((item, index) => (
          <div className="helper-common-box" key={index}>
            <div className={`helper-box border-${item} border`}> </div>
            <span>.border-{item}</span>
          </div>
        ))}
      </div>
    </Col>
  )
}

export default BorderWidths