import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const viewProcon = async (data: { identifier: string }): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/procon/${data.identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Procon", error);
    throw error;
  }
};

const listProcon = async (companyIdentifier: string): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_URL}/procon/${companyIdentifier}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error("Error fetching Procon", error);
    throw error;
  }
};

const createProcon = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/procon`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating Procon", error);
    throw error;
  }
};

const updateProcon = async (identifier: string, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/procon/${identifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating Procon", error);
    throw error;
  }
};

const deleteProcon = async (identifier: string, companyIdentifier: string): Promise<boolean> => {
  try {
    const response = await axios.delete(
      `${API_URL}/procon/${identifier}/${companyIdentifier}`,
      getAuthHeaders()
    );
    return response.status === 204;
  } catch (error) {
    console.error("Error deleting Procon", error);
    throw error;
  }
};

const createByFileProcon = async (companyIdentifier: string, data: any) => {
  try {
    const formData = new FormData();
    formData.append("file", data.files);

    const response = await axios.post(
      `${API_URL}/procon/import-by-file/${companyIdentifier}`,
      formData,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating contact by file", error);
    throw error;
  }
};


export { viewProcon, listProcon, createProcon, updateProcon, deleteProcon, createByFileProcon };
