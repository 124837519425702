import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ApiUrlState {
  url: string;
}

const initialState: ApiUrlState = {
  // url: "https://m2cdigital-api-dev.com.br",
  url: "https://m2cdigital-api.com.br",
  // url: "http://localhost:3001",
};

const apiUrlSlice = createSlice({
  name: "apiUrl",
  initialState,
  reducers: {
    setApiUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
  },
});

export const { setApiUrl } = apiUrlSlice.actions;

export default apiUrlSlice.reducer;
