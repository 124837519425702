import { Card, CardBody, Col } from "reactstrap";
import { Monthly, TopCategoriesHeading, Weekly, Yearly } from "../../../../utils/Constant";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import ReactApexChart from "react-apexcharts";
import { topCategoriesChartData } from "../../../../Data/Dashboard/DashboardChart/DashboardChart";
import TopCategoriesDetails from "./TopCategoriesDetails";

const TopCategories = () => {
  return (
    <Col lg="4" md="6" className="box-col-4">
      <Card className="categories-card">
        <CommonCardHeader headClass="pb-0" title={TopCategoriesHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody>
          <div className="categories-chart">
            <ReactApexChart options={topCategoriesChartData} series={topCategoriesChartData.series} height={392} type="donut" id="categories" />
            <TopCategoriesDetails />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TopCategories;
