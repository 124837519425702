import ContainerFile from "../../../Component/Campaigns/File/File";

const File = () => {
  return (
    <div className="page-body">
      <ContainerFile />
    </div>
  );
};

export default File;
