import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const sendCampaignByFile = async (
  campaignIdentifier: any, 
  companyIdentifier: string, 
  contactFile: any,
  blacklists: any,
  isScheduled: any,
  scheduleDate: any,
  scheduleTime: any,
  scheduleTimezone: any
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("file", contactFile);
    formData.append("blacklists", blacklists);
    formData.append("is_scheduled", isScheduled);
    formData.append("schedule_date", scheduleDate);
    formData.append("schedule_time", scheduleTime);
    formData.append("schedule_timezone", scheduleTimezone);

    const response = await axios.post(
      `${API_URL}/campaigns/send-campaign/${campaignIdentifier}/${companyIdentifier}`,
      formData,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating campaign", error);
    throw error;
  }
};

const sendCampaign = async (
  campaignIdentifier: any, 
  companyIdentifier: string, 
  numbers: string,
  blacklists: any,
  isScheduled: any,
  scheduleDate: any,
  scheduleTime: any,
  scheduleTimezone: any
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("numbers", numbers);
    formData.append("blacklists", blacklists);
    formData.append("is_scheduled", isScheduled);
    formData.append("schedule_date", scheduleDate);
    formData.append("schedule_time", scheduleTime);
    formData.append("schedule_timezone", scheduleTimezone);

    const response = await axios.post(
      `${API_URL}/campaigns/send-campaign/${campaignIdentifier}/${companyIdentifier}`,
      formData,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating campaign", error);
    throw error;
  }
};


const listCampaignByCompany = async (companyIdentifier: any | undefined): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/campaigns/by-company/${companyIdentifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching campaign", error);
    throw error;
  }
};

const listServices = async (companyIdentifier: any | undefined): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/by-company/${companyIdentifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching services", error);
    throw error;
  }
};

const viewCampaign = async (campaignIdentifier: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/campaigns/${campaignIdentifier}/${companyIdentifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching campaign", error);
    throw error;
  }
};


const viewCampaignReceipt = async (id: any, id_campaign: any): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/campaign-receipts/${id_campaign}/${id}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching campaign receipt", error);
    throw error;
  }
};

const createCampaign = async (data: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/campaigns/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating campaign", error);
    throw error;
  }
};

const updateCampaign = async (data: any, id: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/campaigns/${id}/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating campaign", error);
    throw error;
  }
};

const createCampaignReceipt = async (data: any): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("campaign_identifier", data.campaign_identifier);
    formData.append("company_identifier", data.company_identifier);

    if (data.type === "contact_list") {
      formData.append("contact_list_identifier", data.contact_list_identifier);
    } else if (data.type === "file" && data.files) {
      data.files.forEach((file: File) => {
        formData.append("files", file);
      });
    } else {
      formData.append("contacts", data.contacts);
    }

    const response = await axios.post(
      `${API_URL}/campaign-receipts`,
      formData,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    console.error("Error creating campaign receipt", error);
    throw error;
  }
};

const deleteCampaign = async (campaignIdentifier: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.delete(
      `${API_URL}/campaigns/${campaignIdentifier}/${companyIdentifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting campaign", error);
    throw error;
  }
};

export {
  sendCampaignByFile,
  sendCampaign,
  listCampaignByCompany,
  listServices,
  viewCampaign,
  viewCampaignReceipt,
  createCampaign,
  updateCampaign,
  createCampaignReceipt,
  deleteCampaign,
};
