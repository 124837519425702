import { H4, H6, LI, SVG, UL } from "../../../../AbstractElements";
import { stockReportData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";

const StockReportDetails = () => {
  return (
    <div className="stock-report mt-4 p-3 pb-0 pt-0">
      <UL className="simple-list flex-row">
        {stockReportData.map((data, i) => (
          <LI key={i}>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <H4>{data.amount}</H4>
                <SVG className="ms-1" iconId={data.svgIcon} />
                <H6 className={`font-${data.color}`}>{data.percentage}</H6>
              </div>
            </div>
            <span>{data.title}</span>
          </LI>
        ))}
      </UL>
    </div>
  );
};

export default StockReportDetails;
