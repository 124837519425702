import { Input, Label } from "reactstrap";
import { openInvoicesTableBodyData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";
import { Btn, H6, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";
import CardHeaderDropDown from "../../../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import { Monthly, Weekly, Yearly } from "../../../../utils/Constant";

const OpenInvoicesTableBody = () => {
  return (
    <tbody>
      {openInvoicesTableBodyData.map((data, i) => (
        <tr key={i}>
          <td>
            <div className="form-check">
              <Input type="checkbox" />
              <Label />
            </div>
          </td>
          <td>{data.invoiceNo}</td>
          <td>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <Image src={dynamicImage(`dashboard-2/user/${data.image}`)} alt="product" />
              </div>
              <div className="flex-grow-1 ms-3">
                <Link to={`${process.env.PUBLIC_URL}/ecommerce/productlist`}>
                  <H6>{data.name}</H6>
                  <span>{data.email}</span>
                </Link>
              </div>
            </div>
          </td>
          <td>
            <Btn color={data.color}>{data.status}</Btn>
          </td>
          <td>{data.amount}</td>
          <td>{data.date}</td>
          <td>
            <CardHeaderDropDown mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default OpenInvoicesTableBody;
