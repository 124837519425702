import { Col } from 'reactstrap'
import { H3 } from '../../../../AbstractElements'
import { borderColorCartData } from '../../../../Data/Ui-Kits/HelperClasses/HelperClassesData'
import { BorderColor } from '../../../../utils/Constant'

const BorderColors = () => {
  return (
    <Col xxl="3" sm="6">
      <div className="border-wrapper h-100 alert-light-light dark-helper">
        <H3 className="mb-3">{BorderColor}</H3>
        <div className="helper-common-box">
          <div className="helper-box border-primary border"></div>
          <span>.border-primary</span>
        </div>
        {borderColorCartData.map((item, index) => (
          <div className="helper-common-box" key={index}>
            <div className={`helper-box border-${item} border`}></div>
            <span>.border-{item}</span>
          </div>
        ))}
      </div>
    </Col>
  )
}

export default BorderColors