import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { deleteGateway, duplicateGateway, listGateways } from "../../../Service/gateway";
import { format } from "date-fns";
import { Badges } from "../../../AbstractElements";
import { ca } from "date-fns/locale";

const ContainerGateway = () => {
  const { t } = useTranslation();

  const mainTitle = "gateway";
  const base = `${process.env.PUBLIC_URL}/configurations/companies`;

  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const response = await listGateways();
      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const handleDuplicate = async (id: string | undefined) => {
    try {
      const duplicate = await duplicateGateway(id);

      if (duplicate) {
        const response = await listGateways();
        setData(response);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }

  const ExcludeConfirm = (id: string | undefined) => {
    SweetAlert.fire({
      title: t("alert_title"),
      text: t("alert_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("alert_cancel"),
      confirmButtonText: t("alert_button"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteGateway(id)
          .then(() => {
            fetchData();
            SweetAlert.fire({
              title: t("alert_confirm_title"),
              text: t("alert_confirm_text_gateway"),
              icon: "success",
            });
          })
          .catch((error: any) => {
            console.log("error", error);

            let message = t("alert_confirm_text_gateway_error");

            if (error?.response?.data?.message === "Gateway is active") {
              message = t("alert_confirm_text_gateway_error_active");
            }

            SweetAlert.fire({
              title: t("alert_confirm_title_error"),
              text: message,
              icon: "error",
            });
          });
      }
    });
  };

  const ColumnAction = ({ row }: { row: any }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleItemClick = (action: string, id?: string) => {
      if (action === "delete") {
        ExcludeConfirm(id);
        setDropdownOpen(false);
      } else if (action === "edit") {
        navigate(`${process.env.PUBLIC_URL}/configurations/gateway/edit/${id}`);
        setDropdownOpen(false);
      } else if (action === "duplicate") {
        handleDuplicate(id);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("edit", row.identifier)}>
            {t("edit")}
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick("duplicate", row.identifier)}>
            {t("duplicate")}
          </DropdownItem>
          <DropdownItem
            onClick={() => handleItemClick("delete", row.identifier)}
          >
            {t("delete")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const StatusChip = ({ status }: any) => {
    return status ? (
      <Badges className="badge-light-success" color="transparant">
        {t("active")}
      </Badges>
    ) : (
      <Badges className="badge-light-danger" color="transparant">
        {t("inactive")}
      </Badges>
    );
  };

  const columns = [
    {
      name: t("name"),
      selector: (row: any) => `${row.name}`,
      sortable: true,
    },
    {
      name: t("server"),
      selector: (row: any) => `${row.host}`,
      sortable: true,
    },
    {
      name: t("active"),
      selector: (row: any) => `${row.active}`,
      cell: (row: any) => <StatusChip status={row.active} />,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "yyyy-MM-dd HH:mm")}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);
  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };
  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <div style={{ visibility: "hidden" }}>
                  <CardHeaderCommon title={t("CARDHEADERHIDDEN")} />
                </div>
                <div
                  className="btn-search-companies"
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    color="primary"
                    tag={Link}
                    to={`${process.env.PUBLIC_URL}/configurations/gateway/add`}
                  >
                    {t("add_gateway")}
                  </Button>
                </div>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    progressComponent
                    customStyles={customStyles}
                    data={data}
                    pagination
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerGateway;
