import ContainerTransactionAdd from "../../../Component/Financial/Transactions/TransactionAdd";

const TransactionsAdd = () => {
  return (
    <div className="page-body">
      <ContainerTransactionAdd />
    </div>
  );
};

export default TransactionsAdd;
