import { Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, FormGroup, Label } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { listReports, listReportsByRoot } from "../../../Service/reports";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Btn } from "../../../AbstractElements";
import * as Yup from "yup";
import { toZonedTime } from 'date-fns-tz'

const ContainerSMSReports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mainTitle = "reports";
  const base = `${process.env.PUBLIC_URL}/campaigns`;

  const { id } = useParams<{ id: string }>();
  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const companyType = userData ? JSON.parse(userData)?.userInfo?.company_type : null;

  const [data, setData] = useState<any>();

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const [initialDate, setInitialDate] = useState<Date>(new Date());
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  console.log('INITIAL DATE', initialDate);
  console.log('FINAL DATE', finalDate);

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      initial_date: "",
      final_date: "",
      grouper: false,
    });


  const fetchData = useCallback(async () => {
    try {
      let response: any = [];

      const commonData = {
        initial_date: format(initialDate, "yyyy-MM-dd"),
        final_date: format(finalDate, "yyyy-MM-dd"),
      };

      if (companyType && companyType === "owner") {
        response = await listReportsByRoot(identifier, commonData);
      } else {
        response = await listReports(identifier, commonData);
      }

      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id, identifier]);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      let response: any = [];

      console.log('VALUES', values);

      const iniDate = toZonedTime(values.initial_date, userTimeZone);
      const endDate = toZonedTime(values.final_date, userTimeZone);

      console.log('INITIAL DATE', iniDate);
      console.log('FINAL DATE', endDate);

      setInitialDate(iniDate);
      setFinalDate(endDate);
      
      const commonData = {
        initial_date: format(iniDate, "yyyy-MM-dd"),
        final_date: format(endDate, "yyyy-MM-dd"),
        grouper: values.grouper,
      };

      if (companyType && companyType === "owner") {
        response = await listReportsByRoot(identifier, commonData);
      } else {
        response = await listReports(identifier, commonData);
      }

      setData(response);
      setValidationFormInitialValue(commonData);
    } catch (error) {
      console.error("Error saving gateway", error);
      setSubmitError(true);
    }
  };

  const formValidationSchema = Yup.object().shape({
    initial_date: Yup.string().required(t("required_field")),
    final_date: Yup.string().required(t("required_field")),
    grouper: Yup.boolean().required(t("required_field")),
  });

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleItemClick = (action: any) => {
      if (action === "details") {
        let params = '?';
        
        if (row?.row?.company) {
          params += `company=${row?.row?.company_identifier}`;
        }

        if (row?.row?.campaign && row?.row?.campaign?.toLowerCase() !== 'all') {
          if (params.length > 1) {
            params += `&campaign=${row?.row?.campaign_identifier}`;
          } else {
            params += `campaign=${row?.row?.campaign_identifier}`;
          }
        }
        
        if (validationFormInitialValue) {
          if (validationFormInitialValue.initial_date) {
            if (params.length > 1) {
              params += `&initial_date=${validationFormInitialValue.initial_date}`;
            } else {
              params += `initial_date=${validationFormInitialValue.initial_date}`;
            }
          }

          if (validationFormInitialValue.final_date) {
            if (params.length > 1) {
              params += `&final_date=${validationFormInitialValue.final_date}`;
            } else {
              params += `final_date=${validationFormInitialValue.final_date}`;
            }
          }
        }

        console.log('PARAMS', params);
        
        navigate(
          `${process.env.PUBLIC_URL}/campaigns/sms/reports/details${params}`
        );
        setDropdownOpen(false);
      } 
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("details")}>
            {t("details")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const columns = [
    {
      name: t("Company"),
      selector: (row: any) => `${row.company}`,
      sortable: true,
    },
    {
      name: t("Campaign"),
      selector: (row: any) => `${row.campaign}`,
      sortable: true,
    },
    {
      name: t("Total"),
      selector: (row: any) => `${row.total}`,
      sortable: true,
    },
    {
      name: t("Delivered"),
      selector: (row: any) => `${row.delivered}`,
      sortable: true,
    },
    {
      name: t("Undelivered"),
      selector: (row: any) => `${row.undelivered}`,
      sortable: true,
    }, 
    {
      name: t("EnRoute"),
      selector: (row: any) => `${row.enroute}`,
      sortable: true,
    },
    {
      name: t("Others"),
      selector: (row: any) => `${row.others}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction 
        row={row} 
      />,
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("campaigns")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <Formik
                    initialValues={validationFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={formValidationSchema}
                    enableReinitialize={true}
                  >
                    {({ errors }) => (
                      <Form className="g-3 needs-validation custom-input">
                        <Row>
                          <Col xs="12" md="4">
                            <FormGroup>
                              <Label check>{t("initial_date")}</Label>
                              <Field
                                name="initial_date"
                                type="date"
                                className={`form-control ${
                                  submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
                                }`}
                                placeholder={t("initial_date_placeholder")}
                              />
                              <ErrorMessage
                                name="initial_date"
                                component="span"
                                className="invalid-feedback"
                              />
                            </FormGroup>  
                          </Col>
                          <Col xs="12" md="4">
                            <FormGroup>
                              <Label check>{t("final_date")}</Label>
                              <Field
                                name="final_date"
                                type="date"
                                className={`form-control ${
                                  submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
                                }`}
                                placeholder={t("final_date_placeholder")}
                              />
                              <ErrorMessage
                                name="final_date"
                                component="span"
                                className="invalid-feedback"
                              />
                            </FormGroup>  
                          </Col>
                          <Col xs="12" md="4">
                            <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                              <Label check>{t("group_by_campaign")}</Label>
                              <Label className="switch">
                                <Field type="checkbox" name="grouper" />
                                <span className="switch-state" />
                              </Label>
                              <ErrorMessage
                                name="grouper"
                                component="span"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12">
                            <Btn
                              color="primary"
                              type="submit"
                              onClick={() => setSubmitError(true)}
                            >
                              {t("filter")}
                            </Btn>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    subHeader
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerSMSReports;
