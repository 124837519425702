import { Card, CardBody, Col, Container, Row, FormGroup, Label } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { format } from "date-fns";
import { listReports, listReportsByRoot, listReportsDetailed, listReportsDetailedByRoot } from "../../../Service/reports";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Btn } from "../../../AbstractElements";
import * as Yup from "yup";
import Chart from "react-google-charts";
import { SMSReportStatusEnum } from "../../../Enums/sms-report.status.enum";
import { Badges } from "../../../AbstractElements";
import { formatInTimeZone } from 'date-fns-tz'

const ContainerSMSReportsDetails = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const mainTitle = "reports_detailed";
  const base = `${process.env.PUBLIC_URL}/campaigns`;

  const { id } = useParams<{ id: string }>();
  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;
  
  const companyType = userData ? JSON.parse(userData)?.userInfo?.company_type : null;

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [detailedData, setDetailedData] = useState<any>([]);

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const [graphData, setGraphData] = useState<any>();

  const [statusTableData, setStatusTableData] = useState<any>([]);

  const StatusChip = ({ status }: any) => {
    switch (status) {
      case SMSReportStatusEnum.DELIVERED:
        return (
          <Badges className="graph-color-2 btn" color="transparant">
            {t("Delivered")}
          </Badges>
        );
      
      case SMSReportStatusEnum.UNDELIVERED:
        return (
          <Badges className="graph-color-5 btn" color="transparant">
            {t("Undelivered")}
          </Badges>
        );

      case SMSReportStatusEnum.SCHEDULED:
        return (
          <Badges className="graph-color-6 btn" color="transparant">
            {t("Scheduled")}
          </Badges>
        );

        case SMSReportStatusEnum.ENROUTE:
          return (
            <Badges className="graph-color-3 btn" color="transparant">
              {t("EnRoute")}
            </Badges>
          );

        case SMSReportStatusEnum.REJECTED:
          return (
            <Badges className="graph-color-8 btn" color="transparant">
              {t("Rejected")}
            </Badges>
          );

      case SMSReportStatusEnum.ACCEPTED:
        return (
          <Badges className="graph-color-9 btn" color="transparant">
            {t("Accepted")}
          </Badges>
        );

      case SMSReportStatusEnum.INVALID_NUMBER:
        return (
          <Badges className="graph-color-1 btn" color="transparant">
            {t("InvalidNumber")}
          </Badges>
        );

      case SMSReportStatusEnum.PROCESSING:
        return (
          <Badges className="graph-color-7 btn" color="transparant">
            {t("Processing")}
          </Badges>
        );

      case SMSReportStatusEnum.WAITING:
        return (
          <Badges className="graph-color-4 btn" color="transparant">
            {t("Waiting")}
          </Badges>
        );

      case SMSReportStatusEnum.ERROR:
        return (
          <Badges className="graph-color-13 btn" color="transparant">
            {t("NotSent")}
          </Badges>
        );

      case SMSReportStatusEnum.EXPIRED:
        return (
          <Badges className="graph-color-4 btn" color="transparant">
            {t("Expired")}
          </Badges>
        );

      case SMSReportStatusEnum.UNKNOWN:
      default:
        return (
          <Badges className="graph-color-11 btn" color="transparant">
            {t("Unknown")}
          </Badges>
        );
    }
  };

  const fetchData = useCallback(async () => {
    try {
      let response: any = [];
      let responseDetailed: any = [];

      const queryParams = new URLSearchParams(location.search);

      let listParams = {
        company_identifier: queryParams.get('company'),
        campaign_identifier: queryParams.get('campaign'),
        initial_date: queryParams.get('initial_date') ?? formatInTimeZone(new Date(), userTimeZone, "yyyy-MM-dd"),
        final_date: queryParams.get('final_date') ?? formatInTimeZone(new Date(), userTimeZone,"yyyy-MM-dd"),
      };

      if (companyType && companyType === "owner") {
        response = await listReportsByRoot(identifier, listParams);
        responseDetailed = await listReportsDetailedByRoot(identifier, listParams);
      } else {
        response = await listReports(identifier, listParams);
        responseDetailed = await listReportsDetailed(identifier, listParams);
      }

      setGraphData([
        ["Status", "Quantity"],
        [t("Delivered"), response[0].delivered ?? 0],
        [t("Undelivered"), response[0].undelivered ?? 0],
        [t("Scheduled"), response[0].scheduled ?? 0],
        [t("Enroute"), response[0].enroute ?? 0],
        [t("Rejected"), response[0].rejected ?? 0],
        [t("Accepted"), response[0].accepted ?? 0],
        [t("InvalidNumber"), response[0].invalidNumber ?? 0],
        [t("Processing"), response[0].processing ?? 0],
        [t("Waiting"), response[0].waiting ?? 0],
        [t("Unknown"), response[0].unknown ?? 0],
        [t("Expired"), response[0].expired ?? 0],
        [t("NotSent"), response[0].error ?? 0],
      ])

      setStatusTableData([
        {
          status: t("Processing"),
          quantity: response[0].processing,
        },
        {
          status: t("Waiting"),
          quantity: response[0].waiting,
        },
        {
          status: t("Delivered"),
          quantity: response[0].delivered,
        },
        {
          status: t("Undelivered"),
          quantity: response[0].undelivered,
        },
        {
          status: t("Scheduled"),
          quantity: response[0].scheduled,
        },
        {
          status: t("Enroute"),
          quantity: response[0].enroute,
        },
        {
          status: t("Rejected"),
          quantity: response[0].rejected,
        },
        {
          status: t("Accepted"),
          quantity: response[0].accepted,
        },
        {
          status: t("Unknown"),
          quantity: response[0].unknown,
        },
        {
          status: t("InvalidNumber"),
          quantity: response[0].invalidNumber,
        },
        {
          status: t("Expired"),
          quantity: response[0].expired,
        },
        {
          status: t("NotSent"),
          quantity: response[0].error
        },
        {
          status: t('cost'),
          quantity: `${t('$')} ${(Math.floor(Number(response[0]?.cost ?? 0) * 100) / 100).toFixed(2)}`
        },
      ]);

      setDetailedData(responseDetailed);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id, identifier]);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      let response: any = [];
      let responseDetailed: any = [];

      const queryParams = new URLSearchParams(location.search);

      const iniDate = queryParams.get('initial_date') ?? formatInTimeZone(new Date(), userTimeZone, "yyyy-MM-dd");
      const endDate = queryParams.get('final_date') ?? formatInTimeZone(new Date(), userTimeZone, "yyyy-MM-dd");

      let listParams = {
        company_identifier: queryParams.get('company'),
        campaign_identifier: queryParams.get('campaign'),
        initial_date: values.initial_date ?? iniDate,
        final_date: values.final_date ?? endDate,
      };

      if (companyType && companyType === "owner") {
        response = await listReportsByRoot(identifier, listParams);
        responseDetailed = await listReportsDetailedByRoot(identifier, listParams);
      } else {
        response = await listReports(identifier, listParams);
        responseDetailed = await listReportsDetailed(identifier, listParams);
      }

      setDetailedData(responseDetailed);

      setGraphData([
        ["Status", "Quantity"],
        [t("Delivered"), response[0].delivered ?? 0],
        [t("Undelivered"), response[0].undelivered ?? 0],
        [t("Scheduled"), response[0].scheduled ?? 0],
        [t("Enroute"), response[0].enroute ?? 0],
        [t("Rejected"), response[0].rejected ?? 0],
        [t("Accepted"), response[0].accepted ?? 0],
        [t("InvalidNumber"), response[0].invalidNumber ?? 0],
        [t("Processing"), response[0].processing ?? 0],
        [t("Waiting"), response[0].waiting ?? 0],
        [t("Unknown"), response[0].unknown ?? 0],
        [t("Expired"), response[0].expired ?? 0],
        [t("NotSent"), response[0].error ?? 0],
      ])

      setStatusTableData([
        {
          status: t("Delivered"),
          quantity: response[0].delivered,
        },
        {
          status: t("Undelivered"),
          quantity: response[0].undelivered,
        },
        {
          status: t("Scheduled"),
          quantity: response[0].scheduled,
        },
        {
          status: t("Enroute"),
          quantity: response[0].enroute,
        },
        {
          status: t("Rejected"),
          quantity: response[0].rejected,
        },
        {
          status: t("Accepted"),
          quantity: response[0].accepted,
        },
        {
          status: t("Expired"),
          quantity: response[0].expired,
        },
        {
          status: t("Unknown"),
          quantity: response[0].unknown,
        },
        {
          status: t("InvalidNumber"),
          quantity: response[0].invalidNumber,
        },
        {
          status: t("Processing"),
          quantity: response[0].processing,
        },
        {
          status: t("Waiting"),
          quantity: response[0].waiting,
        },
        {
          status: t("NotSent"),
          quantity: response[0].error
        },
        {
          status: t('cost'),
          quantity: `${t('$')} ${(Math.floor(Number(response[0]?.cost ?? 0) * 100) / 100).toFixed(2)}`
        },
      ]);
    } catch (error) {
      console.error("Error saving gateway", error);
      setSubmitError(true);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      initial_date: "",
      final_date: "",
    });

  const formValidationSchema = Yup.object().shape({
    initial_date: Yup.string().required(t("required_field")),
    final_date: Yup.string().required(t("required_field")),
  });

  const reviewColumns = [
    {
      name: t("Status"),
      selector: (row: any) => `${row.status}`,
      sortable: true,
    },
    {
      name: t("quantity"),
      selector: (row: any) => `${row.quantity}`,
      sortable: true,
    },
  ];

  const columns = [
    {
      name: t("company"),
      selector: (row: any) => `${row.company}`,
      sortable: true,
    },
    {
      name: t("campaign"),
      selector: (row: any) => `${row.campaign}`,
      sortable: true,
    },
    {
      name: t("phone_number"),
      selector: (row: any) => `${row.phone_number}`,
      sortable: true,
    },
    {
      name: t("status"),
      selector: (row: any) => row.status,
      cell: (row: any) => <StatusChip status={row.status} />,
      sortable: true,
    },
    {
      name: t("submit_date"),
      selector: (row: any) => `${row.submitDate ? format(row.submitDate, 'yyyy-MM-dd HH:mm') : ''}`,
      sortable: true,
    },
    {
      name: t("done_date"),
      selector: (row: any) => `${row.doneDate ? format(row.doneDate, 'yyyy-MM-dd HH:mm') : ''}`,
      sortable: true,
    },
    {
      name: t("cost"),
      selector: (row: any) => `${row.cost}`,
      sortable: true,
    },
    {
      name: t("service"),
      selector: (row: any) => `${row.service}`,
      sortable: true,
    },
    {
      name: t("gateway_message_id"),
      selector: (row: any) => `${row.gateway_message_id}`,
      sortable: true,
    },
    {
      name: t("message"),
      selector: (row: any) => `${row.message}`,
      sortable: true,
    },
  ];

  const graphOptions = {
    is3D: true,
    colors: [
      "#61AE41", 
      "#E61010",
      "#A1A2DB",
      "#44A8D7",
      "#CE1F91",
      "#3E01F5",
      "#FA5205",
      "#832F08",
      "#F6C761",
      "#F5A3A3",
      "#AD7102",
      "#0BB0B9",
    ],
  };

  useEffect(() => {
    fetchData();
    
    const queryParams = new URLSearchParams(location.search);

    setValidationFormInitialValue({
      initial_date: queryParams.get('initial_date') ?? formatInTimeZone(new Date(), userTimeZone, "yyyy-MM-dd"),
      final_date: queryParams.get('final_date') ?? formatInTimeZone(new Date(), userTimeZone, "yyyy-MM-dd"),
    });
  }, [fetchData]);

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("campaigns")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <Col sm="5">
                    <div className="table-responsive">
                      <DataTable
                        columns={reviewColumns}
                        data={statusTableData}
                        highlightOnHover
                        striped
                      />
                    </div>
                  </Col>
                  <Col sm="7">
                    <div> 
                      <Chart
                        chartType="PieChart"
                        data={graphData}
                        options={graphOptions}
                        width={"100%"}
                        height={"400px"}
                      />
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                <Formik
                  initialValues={validationFormInitialValue}
                  onSubmit={handleSubmit}
                  validationSchema={formValidationSchema}
                  enableReinitialize={true}
                >
                  {({ errors }) => (
                    <Form className="g-3 needs-validation custom-input">
                      <Row>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label check>{t("initial_date")}</Label>
                            <Field
                              name="initial_date"
                              type="date"
                              className={`form-control ${
                                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
                              }`}
                              placeholder={t("initial_date_placeholder")}
                            />
                            <ErrorMessage
                              name="initial_date"
                              component="span"
                              className="invalid-feedback"
                            />
                          </FormGroup>  
                        </Col>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label check>{t("final_date")}</Label>
                            <Field
                              name="final_date"
                              type="date"
                              className={`form-control ${
                                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
                              }`}
                              placeholder={t("final_date_placeholder")}
                            />
                            <ErrorMessage
                              name="final_date"
                              component="span"
                              className="invalid-feedback"
                            />
                          </FormGroup>  
                        </Col>
                        <Col xs="12">
                          <Btn
                            color="primary"
                            type="submit"
                            onClick={() => setSubmitError(true)}
                          >
                            {t("filter")}
                          </Btn>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
                </div>
                

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={detailedData}
                    pagination
                    subHeader
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerSMSReportsDetails;
