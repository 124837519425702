import ContainerEcommerce from "../../../Component/Dashboard/Ecommerce/Ecommerce"

const Ecommerce = () => {
  return (
    <div className='page-body'>
      <ContainerEcommerce />
    </div>
  )
}

export default Ecommerce