import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const forgotPassword = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(`${API_URL}/auth/forgot/password`, data);
    return response.data;
  } catch (error) {
    console.error("Error during forgot password", error);
    throw error;
  }
};

const resetPassword = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(`${API_URL}/auth/reset/password`, data);
    return response.data;
  } catch (error) {
    console.error("Error during reset password", error);
    throw error;
  }
};

const login = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, data);
    return response.data;
  } catch (error) {
    console.error("Error during login", error);
    throw error;
  }
};

export { forgotPassword, resetPassword, login };
