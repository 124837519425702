import { Col } from "reactstrap";
import { UL } from "../../../AbstractElements";

import ZoomInOut from "./ZoomInOut/ZoomInOut";
import Language from "./Language/Language";
// import DarkMode from "./DarkMode/DarkMode";
import UserProfile from "./UserProfile/UserProfile";
import BalanceHeader from "./BalanceHeader/BalanceHeader";

const RightHeaderIcon = () => {
  return (
    <Col
      xxl="7"
      xl="8"
      className="nav-right col-auto box-col-6 pull-right right-header p-0 ms-auto"
    >
      <UL className="nav-menus flex-row">
        <BalanceHeader />
        <ZoomInOut />
        <Language />
        {/* <DarkMode /> */}
        <UserProfile />
      </UL>
    </Col>
  );
};

export default RightHeaderIcon;
