import { Card, CardBody, Col, Table } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, TeamMembersHeading, Weekly, Yearly } from "../../../../utils/Constant";
import TeamMembersBody from "./TeamMembersBody";

const TeamMembers = () => {
  return (
    <Col xl="4" lg="6" md="6">
      <Card>
        <CommonCardHeader headClass="pb-0" title={TeamMembersHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody className="pt-0 team-members">
          <div className="custom-scrollbar table-responsive">
            <Table className="display" id="team-members" style={{ width: "100%" }}>
              <thead> 
                <tr>
                  <th>Team Name</th>
                  <th>Designation</th>
                  <th>Hours</th>
                  <th>Status</th>
                </tr>
              </thead>
              <TeamMembersBody />
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TeamMembers;
