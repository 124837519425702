import { Card, CardBody, Col } from "reactstrap";
import { H4, Image, P } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { dynamicImage } from "../../../../Service";

const SubscriptionPlan = () => {
  return (
    <Col xl="3" lg="5" md="5">
      <Card className="upgrade-card">
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <H4>Upgrade Your Subscription Plan</H4>
              <P>The goal of this message is to welcome you to our app.</P>
              <Link className="btn btn-primary" to="list-products.html">
                Go Premium
              </Link>
            </div>
            <div className="flex-shrink-0">
              <Image src={dynamicImage(`dashboard-3/upgrade.png`)} alt="user" />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SubscriptionPlan;
