import ContainerMessages from "../../../Component/Campaigns/Messages/Messages";

const Messages = () => {
  return (
    <div className="page-body">
      <ContainerMessages />
    </div>
  );
};

export default Messages;
