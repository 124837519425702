import { CardBody } from "reactstrap";
import { H2 } from "../../../../AbstractElements";

const CommonCardEarningInitial = ({amount}:any) => {
  return (
    <CardBody className="p-0">
      <div className="d-flex p-3 pt-0">
        <H2 className="me-2">{amount}</H2>
      </div>
    </CardBody>
  );
};

export default CommonCardEarningInitial;
