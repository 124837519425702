import React, { useState } from "react";
import { ErrorMessage, Field, Form } from "formik";
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Btn } from "../../../AbstractElements";
import { Typeahead } from "react-bootstrap-typeahead";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  setSubmitError,
  loading,
  operators,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("name_label")}</Label>
            <Field
              name="name"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("name_placeholder")}
            />
            <ErrorMessage
              name="name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("server")}</Label>
            <Field
              name="host"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.host ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="host"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("port")}</Label>
            <Field
              name="port"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.port ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="port"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("user_gateway")}</Label>
            <Field
              name="user"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.user ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="user"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("password_gateway")}</Label>
            <div className="password-input-group">
              <Field
                name="password"
                type={show ? "text" : "password"}
                className={`form-control ${
                  submitErrors &&
                  `${errors.password ? "is-invalid" : "is-valid"}`
                }`}
              />
              <div
                className="show-hide"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setShow(!show)}
              >
                {show ? "Hide" : "Show"}
              </div>
            </div>
            <ErrorMessage
              name="password"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("own_cost")}</Label>
            <InputGroup>
              <InputGroupText>{"R$"}</InputGroupText>
              <Field
                name="own_cost"
                type="number"
                className={`form-control ${
                  submitErrors &&
                  `${errors.own_cost ? "is-invalid" : "is-valid"}`
                }`}
              />
            </InputGroup>
            <ErrorMessage
              name="own_cost"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("sender_number")}</Label>
            <Field
              name="sender"
              type="text"
              className={`form-control ${
                submitErrors && `${errors.user ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="sender"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("speed")}</Label>
            <Field
              name="speed"
              type="number"
              className={`form-control ${
                submitErrors && `${errors.speed ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="speed"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("rule_for_portability")}</Label>
            <Field
              as="select"
              name="portability_delivery"
              className={`form-control ${
                submitErrors &&
                `${errors.portability_delivery ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value=""></option>
              <option value="all">{t("all")}</option>
              <option value="non-ported">{t("non-ported")}</option>
              <option value="ported">{t("ported")}</option>
            </Field>
            <ErrorMessage
              name="portability_delivery"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("rule_for_concatenates")}</Label>
            <Field
              as="select"
              name="concatenated_rule"
              className={`form-control ${
                submitErrors &&
                `${errors.concatenated_rule ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value=""></option>
              <option value="all">{t("all")}</option>
              <option value="non-concatenated">{t("non-concatenated")}</option>
              <option value="concatenated">{t("concatenated")}</option>
            </Field>
            <ErrorMessage
              name="concatenated_rule"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("concatenated_send")}</Label>
            <Field
              as="select"
              name="concatenated_delivery"
              className={`form-control ${
                submitErrors &&
                `${errors.concatenated_delivery ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value=""></option>
              <option value="payload">{t("payload")}</option>
            </Field>
            <ErrorMessage
              name="concatenated_delivery"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("operators")}</Label>
            <Field name="operator">
              {({ field, form }: any) => (
                <Typeahead
                  id="operator"
                  options={operators}
                  multiple
                  onChange={(selected) => {
                    form.setFieldValue(field.name, selected);
                  }}
                  selected={field.value}
                  size="sm"
                  className={`custom-typeahead ${
                    submitErrors &&
                    `${errors.operator ? "is-invalid" : "is-valid"}`
                  }`}
                  placeholder="vivo, tim, claro, nextel, outros"
                />
              )}
            </Field>
            <ErrorMessage
              name="operator"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>

        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("active")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="active" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="active"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("fail_over")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="failover" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="failover"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("charge_mo")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="charge_mo_processed" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="charge_mo_processed"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={loading}
          >
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
