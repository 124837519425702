import ContainerTransactions from "../../../Component/Financial/Transactions/Transactions";

const Transactions = () => {
  return (
    <div className="page-body">
      <ContainerTransactions />
    </div>
  );
};

export default Transactions;
