import ContainerBalance from "../../../Component/Financial/Balance/Balance";

const Balance = () => {
  return (
    <div className="page-body">
      <ContainerBalance />
    </div>
  );
};

export default Balance;
