import { H4, H6, LI, UL } from "../../../../AbstractElements";
import { topCategoriesDetailsData } from "../../../../Data/Dashboard/Ecommerce/Ecommerce";

const TopCategoriesDetails = () => {
  return (
    <UL className="simple-list flex-row">
      {topCategoriesDetailsData.map((data, i) => (
        <LI key={i}> 
          <div className="d-flex">
            <div className="flex-shrink-0 me-2">
              <span className={`bg-${data.color}`} />
            </div>
            <div className="flex-grow-1">
              <H4>{data.percentage}</H4>
              <H6>{data.title}</H6>
            </div>
          </div>
        </LI>
      ))}
    </UL>
  );
};

export default TopCategoriesDetails;
