import {
  All,
  Audios,
  Images,
  Settings,
  Tools,
  Videos,
} from "../../../utils/Constant";

export const navData = [
  { id: 1, icon: "target", tittle: All },
  { id: 2, icon: "image", tittle: Images },
  { id: 3, icon: "video-clapper", tittle: Videos },
  {id: 4, icon: "map-alt", tittle: Audios },
  {id: 5, color: "success", tittle: Settings },
  {id: 6, color: "secondary", tittle: Tools },
];

export const searchTabsData = [
  {
    id: 1,
    title: "PixelStrap - Portfolio | ThemeForest",
    url: "https://themeforest.net/user/pixelstrap/portfolio/",
    detail:
      "2023's best selling creative wp themes. the #1 source of premium wp themes! themeforest 45,000+ wp themes & website templates from $2. check it out!",
    star: "3 stars",
    vote: "590 votes",
    news: "Theme",
    videoLink: "https://www.youtube.com/embed/CJnfAXlBRTE",
  },
  {
    id: 2,
    title: "PixelStrap - Portfolio | ThemeForest",
    url: "PixelStrap - Portfolio | ThemeForestthemeforest.net › user ›",
    detail:
      "the #1 marketplace for premium website templates, including themes for wordpress, magento, drupal, joomla, and more. create a website, fast.",
    star: "3 stars",
    vote: "590 votes",
    news: "Theme",
    videoLink: "https://www.youtube.com/embed/wpmHZspl4EM",
  },
  {
    id: 3,
    title: "Morbi feugiat mauris vel semper fringilla.",
    url: "https://themeforest.net/user/pixelstrap/portfolio",
    detail:
      "Riho introduces a ielts coaching, toefl coaching, gre coaching, gmat coaching, sat coaching in surat.",
    star: "3 stars",
    vote: "590 votes",
    news: "Theme",
    videoLink: "https://www.youtube.com/embed/-L4gEk7cOfk",
    showStar: [true, true, true, false, false],
  },
  {
    id: 4,
    title: "Morbi feugiat mauris vel semper fringilla.",
    url: "https://themeforest.net/user/pixelstrap/portfolio",
    detail:
      "Riho introduces a ielts coaching, toefl coaching, gre coaching, gmat coaching, sat coaching in surat.",
    star: "3 stars",
    vote: "590 votes",
    news: "Theme",
    videoLink: "https://www.youtube.com/embed/wpmHZspl4EM",
    showStar: [true, true, false, false, false],
  },
];

export const searchInformation = [
  {
    title: "Pixelstrap Website Templates from ThemeForest",
    url: "https://themeforest.net/user/pixelstrap/portfolio",
    detail:
      "get 59 pixelstrap website templates on themeforest. buy pixelstrap website templates from $7. all created by our global community of independent web ...",
    star: "3 stars",
    vote: "590 votes",
    news: "Theme",
    videoLink: "https://www.youtube.com/embed/wpmHZspl4EM",
    showStar: [true, true, false, false, false],
  },
  {
    title: "Morbi feugiat mauris vel semper fringilla.",
    url: "https://themeforest.net/user/pixelstrap/portfolio",
    detail:
      "riho introduces a ielts coaching, toefl coaching, gre coaching, gmat coaching, sat coaching in surat.",
    star: "3 stars",
    vote: "590 votes",
    news: "Theme",
    videoLink: "https://www.youtube.com/embed/wpmHZspl4EM",
    showStar: [true, true, false, false, false],
  },
];

export const galleryImagePath = [
  "01.jpg",
  "02.jpg",
  "03.jpg",
  "04.jpg",
  "05.jpg",
  "011.jpg",
  "010.jpg",
  "08.jpg",
];
