import { Col, Container, Row } from 'reactstrap'
import CardViewSidebar from '../Common/CardViewSidebar/CardViewSidebar'
import CardsPagination from '../Common/CardViewSidebar/CardsPagination/CardsPagination'
import ListViewCard from './ListViewCard'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { JobSearch, ListView } from '../../../../utils/Constant'

const ListViewContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={ListView} parent={JobSearch} />
      <Container fluid>
        <Row>
          <Col xxl="3" xl="4" className="box-col-4e">
            <div className="md-sidebar">
              <CardViewSidebar />
            </div>
          </Col>
          <Col xxl="9" xl="8" className="box-col-8">
            <ListViewCard />
            <CardsPagination />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ListViewContainer