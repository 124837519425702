import { Link, useNavigate } from "react-router-dom";
import { FeatherIcons, LI, UL } from "../../../../AbstractElements";
import { profilesMessage } from "../../../../Data/LayoutData/HeaderData";
import { useTranslation } from "react-i18next";

const ProfileBox = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClick = (name: string) => {
    if (name === "logout") {
      localStorage.removeItem("M2C:userData");
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
  };

  return (
    <UL className="profile-dropdown onhover-show-div simple-list">
      {profilesMessage.map((data, index) => (
        <LI key={index}>
          <Link to={data.link} onClick={() => handleClick(data.name)}>
            <FeatherIcons iconName={data.icon} />
            <span>{t(data.name)} </span>
          </Link>
        </LI>
      ))}
    </UL>
  );
};

export default ProfileBox;
