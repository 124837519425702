import { Card, CardBody, Col } from "reactstrap";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { Monthly, TrackOrderHeading, Weekly, Yearly } from "../../../../utils/Constant";
import TrackOrderPlace from "./TrackOrderPlace";
import TrackOrderStatus from "./TrackOrderStatus";

const TrackOrder = () => {
  return (
    <Col lg="4" md="6"  className="box-col-4">
      <Card>
        <CommonCardHeader headClass="pb-0" title={TrackOrderHeading} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} mainTitle={true} />
        <CardBody>
          <TrackOrderPlace />
          <TrackOrderStatus />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TrackOrder;
