import { Col, Container, Row } from 'reactstrap'
import CardViewSidebar from '../Common/CardViewSidebar/CardViewSidebar'
import JobApplyForms from './JobApplyForms/JobApplyForms'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { JobApply, JobSearch } from '../../../../utils/Constant'

const JobApplyContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={JobApply} parent={JobSearch} />
      <Container fluid>
        <Row>
          <Col xxl="3" xl="4" className="box-col-4e">
            <div className="md-sidebar">
              <CardViewSidebar />
            </div>
          </Col>
          <Col xxl="9" xl="8" className="box-col-8">
            <JobApplyForms />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default JobApplyContainer