import { TabPane } from "reactstrap";
import CommonTabContentTable from "./CommonTabContentTable";

const SecondTabContent = () => {
  return (
    <TabPane tabId="2">
      <div className="custom-scrollbar">
        <CommonTabContentTable value={3}/>
      </div>
    </TabPane>
  );
};

export default SecondTabContent;
