import ContainerUsersAdd from "../../../Component/Configurations/Users/UsersAdd";

const UsersAdd = () => {
  return (
    <div className="page-body">
      <ContainerUsersAdd />
    </div>
  );
};

export default UsersAdd;
