import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Badges } from "../../../AbstractElements";
import { listUsers, listUsersForRoot } from "../../../Service/users";
import { CompanyTypeEnum } from "../../../Enums/company.type.enum";

const ContainerUsers = () => {
  const { t } = useTranslation();
  const mainTitle = "Users";
  const base = `${process.env.PUBLIC_URL}/configurations/companies/users`;
  const textBase = [{ text: t("user_text") }];
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate()

  const [data, setData] = useState<any>();

  const userData = localStorage.getItem("M2C:userData");

  if (!userData) {
    navigate(`${process.env.PUBLIC_URL}`);
  }

  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const companyType = userData
    ? JSON.parse(userData)?.userInfo?.company_type
    : null;


  const fetchData = useCallback(async () => {
    try {

      if (companyType === CompanyTypeEnum.OWNER) {
        const response: any = await listUsersForRoot(id, identifier);
        setData(response);
      } else {
        const response: any = await listUsers(id);
        setData(response);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id]);

  const ExcludeConfirm = () => {
    SweetAlert.fire({
      title: t("alert_title"),
      text: t("alert_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("alert_cancel"),
      confirmButtonText: t("alert_button"),
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: t("alert_confirm_title"),
          text: t("alert_confirm_text_user"),
          icon: "success",
        });
      }
    });
  };

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleItemClick = (action: any) => {
      if (action === "delete") {
        ExcludeConfirm();
        setDropdownOpen(false);
      } else if (action === "edit") {
        navigate(
          `${process.env.PUBLIC_URL}/configurations/companies/users/edit/${row.row.identifier}/${row.row.company_identifier}`
        );
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("edit")}>
            {t("edit")}
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick("delete")}>
            {t("delete")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const StatusChip = ({ status }: any) => {
    return status ? (
      <Badges className="badge-light-success" color="transparant">
        {t("active")}
      </Badges>
    ) : (
      <Badges className="badge-light-danger" color="transparant">
        {t("inactive")}
      </Badges>
    );
  };

  const columns = [
    {
      name: t("name"),
      selector: (row: any) => `${row.first_name}`,
      sortable: true,
    },
    {
      name: t("email"),
      selector: (row: any) => `${row.email}`,
      sortable: true,
    },
    {
      name: t("company"),
      selector: (row: any) => `${row.company}`,
      sortable: true,
    },
    {
      name: t("Status"),
      selector: (row: any) => row.active,
      cell: (row: any) => <StatusChip status={row.active} />,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];
  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <Breadcrumbs base={base} mainTitle={t(mainTitle)} parent={t("Users")} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <CardHeaderCommon title={t("Users")} span={textBase} />
                <div className="btn-search-companies">
                  <Button
                    color="primary"
                    tag={Link}
                    to={`${process.env.PUBLIC_URL}/configurations/companies/users/add/${id}`}
                  >
                    {t("add_user")}
                  </Button>
                </div>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerUsers;
