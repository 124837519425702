import ReactApexChart from "react-apexcharts";
import { H6, Image } from "../../../../AbstractElements";
import { teamMembersBodyData } from "../../../../Data/Dashboard/Project/Project";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";
import { Href } from "../../../../utils/Constant";

const TeamMembersBody = () => {
  return (
    <tbody>
      {teamMembersBodyData.map((data, i) => (
        <tr key={i}>
          <td>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <Image src={dynamicImage(`dashboard-3/user/${data.image}`)} alt="user"/>
              </div>
              <div className="flex-grow-1 ms-2">
                <Link to={Href}>
                  <H6>{data.name}</H6>
                </Link>
              </div>
            </div>
          </td>
          <td>{data.designation}</td>
          <td>{data.hours}</td>
          <td>
            <div className="member-chart">
              <ReactApexChart options={data.chart} series={data.chart.series} height={80} type="donut" />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TeamMembersBody;
