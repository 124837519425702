import { H4, LI, UL } from '../../../../../AbstractElements'

const ListOfBlogWithoutDetails = () => {
  return (
    <div className="blog-details-main">
      <UL className="blog-social simple-list flex-row">
        <LI>20 April 2024</LI>
        <LI>by: Admin</LI>
        <LI>0 Hits</LI>
      </UL>
      <hr />
      <H4 className="blog-bottom-details">A huge part of it is the incomparable beauty you can encounter every day.People just anymore profound.</H4>
    </div>
  )
}

export default ListOfBlogWithoutDetails