import React, { useState } from "react";
import { ErrorMessage, Form } from "formik";
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createContactBlackListByFile } from "../../../Service/contactsBlackList";
import { useNavigate, useParams } from "react-router-dom";

const FormValidations: React.FC<any> = ({ blackList }) => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;
  const onSubmit = async () => {
    setLoading(true);
    const contactData = {
      files: uploadedFile,
      contact_list_identifier: id,
      company_identifier: identifier,
    };

    const dataToSubmit = contactData;

    try {
      await createContactBlackListByFile(identifier, dataToSubmit);
      setLoading(false);
      setUploadedFile(null);
      toast.success(t("success_saved"));
      navigate(`${process.env.PUBLIC_URL}/contact/blacklist`);
    } catch {
      setUploadedFile(null);
      setLoading(false);
      toast.error(t("error_saved"));
    } finally {
      setUploadedFile(null);
      setLoading(false);
    }
  };

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const handleSubmit = (allFiles: any) => {
    const uploaded = allFiles[0].file;
    setUploadedFile(uploaded);
    allFiles.forEach((f: any) => f.remove());
    toast.success(t('file_ready_to_upload'));
  };
  const handleDownload = () => {
    console.log("chamou arquivo");
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/assets/docs/contatos.csv";
    link.download = "contatos.csv";
    link.click();
    console.log("chamou arquivo final");
  };
  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("import_contact_label")}</Label>
            <Dropzone
              getUploadParams={getUploadParams}
              onSubmit={handleSubmit}
              maxFiles={1}
            />
            {uploadedFile && <div>{t('uploaded_file')}: {uploadedFile.name}</div>}
            <ErrorMessage
              name="files"
              component="span"
              className="invalid-feedback"
            ></ErrorMessage>
          </FormGroup>
        </Col>
        {/* <Col xs="12" md="12">
          <FormGroup className="check-company">
            <Field
              id="activeCheck"
              name="duplicity"
              className={` ${
                submitErrors &&
                `${errors.duplicity ? "is-invalid" : "is-valid"}`
              }`}
              type="checkbox"
            />
            <Label for="activeCheck" className="m-l-10">
              {t("duplicity_label")}
            </Label>
            <ErrorMessage
              name="duplicity"
              className="invalid-feedback"
              component="span"
            />
          </FormGroup>
        </Col> */}
        <Col xs="12" md="6">
          <Label>{t("example_file")}</Label>
          <InputGroup style={{ cursor: "pointer" }}>
            <InputGroupText className="list-light-primary">
              <i className="icofont icofont-download txt-primary"></i>
            </InputGroupText>
            <Button
              style={{ cursor: "pointer" }}
              color="primary"
              onClick={() => {
                handleDownload();
              }}
            >
              {t("download_file")}
            </Button>
          </InputGroup>
        </Col>

        <Col xs="12" style={{ marginTop: "15px" }}>
          <Btn color="primary" onClick={() => onSubmit()} disabled={loading}>
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
