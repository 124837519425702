import { Btn, Image, LI, UL } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { ClientBudgetPropsType } from "../../../../Types/Dashborad/Project";

const UserDetails = ({ data }: ClientBudgetPropsType) => {
  return (
    <div className="user-details customers">
      <UL className="simple-list flex-row">
        {data.userDetail.map((item, i) => (
          <LI className="d-inline-block" key={i}>
            <Image src={dynamicImage(`dashboard-3/user/${item}`)} alt="user" />
          </LI>
        ))}
      </UL>
      <Btn color={data.btnColor}>{data.buttonName}</Btn>
    </div>
  );
};

export default UserDetails;
