import ContainerSMSAccess from "../../../Component/Campaigns/Sms/SmsAccess";

const SMSAccess = () => {
  return (
    <div className="page-body">
      <ContainerSMSAccess />
    </div>
  );
};

export default SMSAccess;
