import { Card, CardBody, Col, Table } from "reactstrap";
import { Monthly, UpcomingDeadlinesHeading, Weekly, Yearly } from "../../../../utils/Constant";
import CommonCardHeader from "../../../../CommonElements/CommonCardHeader/CommonCardHeader";
import UpcomingDeadlinesBody from "./UpcomingDeadlinesBody";

const UpcomingDeadlines = () => {
  return (
    <Col xl="4" md="6" className="proorder-xl-1">
      <Card>
        <CommonCardHeader headClass="pb-0" title={UpcomingDeadlinesHeading} mainTitle={true} firstItem={Weekly} secondItem={Monthly} thirdItem={Yearly} />
        <CardBody className="pt-0 upcoming">
          <div className="custom-scrollbar table-responsive">
            <Table  className="display " id="upcoming-deadlines" style={{ width: "100%" }} >
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Task</th>
                  <th>Deadline</th>
                  <th>Progress</th>
                </tr>
              </thead>
              <UpcomingDeadlinesBody />
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UpcomingDeadlines;
