import { Card, Col } from 'reactstrap'
import CommonProfileHead from '../Common/CommonProfileHead'
import { P } from '../../../../../AbstractElements'
import CommonProfileLike from '../Common/CommonProfileLike'

const ProfileDetails = () => {
  return (
    <Col sm="12">
      <Card>
        <div className="profile-img-style">
          <CommonProfileHead activeTime="9 Month ago" image='7'/>
          <hr />
          <P className="block-ellipsis mb-4">{"Comfort is very important to me. I think people live better in big houses and in big clothes. Design and style should work toward making you look good and feel good without a lot of effort so you can get on with the things that matter. My shows are about the complete woman who swallows it all. Its a question of survival. Those fashion designers are just crazy; but arent we all? You can only go forward by making mistakes."}</P>
          <CommonProfileLike likeNumber={2659} commentsNumber={689} />
        </div>
      </Card>
    </Col>
  )
}

export default ProfileDetails