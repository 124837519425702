import { Card, CardBody, Col, Row } from "reactstrap";
import { H4, H5, H6, SVG } from "../../../../AbstractElements";
import ReactApexChart from "react-apexcharts";
import { CommonTotalRevenueCardType } from "../../../../Types/Dashborad/EcommerceDashboard";

const CommonTotalRevenueCard = ({icon,amount,title,arrowIcon, percentage,chart,classExtra}:CommonTotalRevenueCardType) => {
  return (
    <Col sm="6">
      <Card className="total-sales">
        <CardBody>
          <Row>
            <Col xl="8" md="8" sm="12" className="xl-12 col box-col-12">
              <div className={`d-flex ${classExtra}`}>
                <span>
                  <SVG iconId={icon}/>
                </span>
                <div className="flex-shrink-0">
                  <H4>{amount}</H4>
                  <H6>{title} </H6>
                  <div className="arrow-chart">
                    <SVG iconId={arrowIcon} />
                    <H5 className={arrowIcon === "arrow-chart-up" ? "font-success" : "font-danger"}>{percentage}</H5>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="4" md="4" className="col-4 p-0 ecommerce-chart">
              <div className="earning-chart">
                <ReactApexChart options={chart} series={chart.series} height={chart.chart.height} type={chart.chart.type} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CommonTotalRevenueCard;
