import { useEffect, useState } from "react";
import { Image, LI, P } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import ProfileBox from "./ProfileBox";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const [user, setUser] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("M2C:userData");

    if (!userData) {
      navigate(`${process.env.PUBLIC_URL}`);
    } else {
      const tmpUser = userData ? JSON.parse(userData) : null;

      setUser(tmpUser.userInfo);
    }
  }, []);

  return (
    <LI className="profile-nav onhover-dropdown p-0">
      <div className="d-flex profile-media align-items-center">
        <Image
          className="b-r-10 img-40"
          src={dynamicImage("dashboard/profile.png")}
          alt="user"
        />
        <div className="flex-grow-1">
          <span>{user?.first_name}</span>
          <P className="mb-0">{user?.user_type}</P>
        </div>
      </div>
      <ProfileBox />
    </LI>
  );
};

export default UserProfile;
